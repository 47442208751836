exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".favourite{display:flex;flex-flow:column;flex:1 1 100%;max-width:100%;width:100%;align-self:center;padding:0 1rem}@media (min-width: 900px){.favourite{flex:1 1 70%;max-width:70%;width:70%}}.favourite_header{width:100%;padding-bottom:1rem}.favourite_title{color:#006281;margin-top:1.5rem;margin-bottom:0}.favourite_list{width:100%}.favourite_dropdown{display:flex;flex-flow:row;justify-content:flex-end;margin:-2rem 0 2rem 0;align-items:center}@media (max-width: 900px){.favourite_dropdown{margin:1rem 0 1rem 0;justify-content:start}}.dropdown_page{font-style:italic}.dropdown_item{color:#f8992d}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};