import React from 'react';
import * as Proptypes from 'prop-types';
import { connect } from 'react-redux';
import Heartbeat from '../Heartbeat/Heartbeat';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ScrollWrapper from '../ScrollWrapper/ScrollWrapper';
import './Page.scss';
import EmbedHeartbeat from '../Heartbeat/EmbedHeartbeat';

/**
 * Page wrapper used to add universal components to a page and connect them to Redux.
 * @param {object} props - defined in proptypes
 * @returns {*} - Page container
 * @class
 */
const Page = (props) => {
  const {
    header, footer, children, heartbeat, embed, user, history, location, credentials
  } = props;
  let heartbeatDisplay = <></>;

  if (heartbeat) {
    if (embed) {
      heartbeatDisplay = <EmbedHeartbeat credentials={credentials} />;
    } else {
      heartbeatDisplay = <Heartbeat />;
    }
  }
  return (
    <div className="Page">
      <div id="back-to-top-anchor" />
      {heartbeatDisplay}
      {location.includes('/view-articles') || location.includes('/favourite-articles') ? null : (
        <>
          {header && (
            <Header
              user={user}
              embed={embed}
              history={history}
              location={location}
            />
          )}
        </>
      )}
      {children}
      {footer && (
        <Footer
          embed={embed}
          history={history}
          location={location}
        />
      )}
      <ScrollWrapper />
    </div>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(Page);

Page.propTypes = {
  header: Proptypes.bool,
  footer: Proptypes.bool,
  heartbeat: Proptypes.bool,
  embed: Proptypes.bool.isRequired,
  children: Proptypes.element.isRequired,
  user: Proptypes.shape({
    firstName: Proptypes.string,
    lastName: Proptypes.string,
    role: Proptypes.number,
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func,
  }).isRequired,
  location: Proptypes.string.isRequired,
  credentials: Proptypes.shape({
    third_party_token: Proptypes.string,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
    }),
  }).isRequired,
};

Page.defaultProps = {
  header: true,
  footer: true,
  heartbeat: true,
};
