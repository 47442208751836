import {
  FAVOURITE_ADD_REQUEST, FAVOURITE_ADD_SUCCESS, FAVOURITE_ADD_FAILURE, FAVOURITE_REMOVE_REQUEST,
  FAVOURITE_REMOVE_SUCCESS, FAVOURITE_REMOVE_FAILURE, FAVOURITE_LIST_REQUEST,
  FAVOURITE_LIST_SUCCESS, FAVOURITE_LIST_FAILURE, NOTES_ADD_REQUEST, NOTES_ADD_SUCCESS,
  NOTES_ADD_FAILURE, RESET_REDUX, LOGOUT_COMPLETE, LOGOUT_INVALID_TOKEN
} from '../../actions/actions';
import React from 'react';
import { toast } from 'react-toastify';
import Notification from '../../components/Notification/Notification';

const INIT_STATE = {
  currentList: {},
  loading: true,
  loaded: false,
};

const favouriteLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FAVOURITE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FAVOURITE_LIST_FAILURE:
      return {
        ...state
      };
    case FAVOURITE_LIST_SUCCESS:
      return {
        ...state,
        currentList: action.payload,
        loading: false,
        loaded: true,
      };
    case FAVOURITE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FAVOURITE_REMOVE_SUCCESS:

      const newFavouritesMessageAnswers1 = [...state.currentList.message.answers];
      const newList = newFavouritesMessageAnswers1.filter((favourite) => (
        favourite.doc_id !== action.payload.id
      ));

      const newFavList2 = { message: { answers: newList } };

      return {
        ...state,
        currentList: newFavList2,
        loading: false
      };

    case FAVOURITE_ADD_REQUEST:
      return {
        ...state
      };

    case FAVOURITE_ADD_SUCCESS:
      const newCurrentFavList = { ...state.currentList };
      const newFavouritesMessage = { ...state.currentList.message };
      const newFavouritesMessageAnswers = [...state.currentList.message.answers];

      let newFav = {};
      newFav.doc_id = action.payload.doc_id;
      newFav.favourite_id = action.payload.favourite_id;

      newFavouritesMessageAnswers.push(newFav);
      newFavouritesMessage.answers = newFavouritesMessageAnswers;
      newCurrentFavList.message = newFavouritesMessage;

      return {
        ...state,
        currentList: newCurrentFavList,
        loading: false
      };

    case NOTES_ADD_REQUEST:
      return {
        ...state
      };
    case NOTES_ADD_SUCCESS:
      toast.success(
        <Notification
          title={action.payload.notes.message.body === null ? "Notes Updated" : "Notes Created"}
          body={""}
        />
      );
      const newNotes = action.payload.notes;
      const favId = action.payload.favouriteId;

      const newCurrentNotesList = { ...state.currentList };
      const newNotesMessage = { ...state.currentList.message };
      const newNotesMessageAnswers = [...state.currentList.message.answers];

      for (let i = 0; i < newNotesMessageAnswers.length; i++) {
        if (newNotesMessageAnswers[i].favourite_id == favId) {
          newNotesMessageAnswers[i].notes = newNotes.message;
        }
      }

      newNotesMessage.answers = newNotesMessageAnswers;
      newCurrentNotesList.message = newNotesMessage;

      return {
        ...state,
        currentList: newCurrentNotesList,
        loading: false
      };

    case NOTES_ADD_FAILURE:
      return {
        ...state
      };

    case LOGOUT_INVALID_TOKEN:
    case LOGOUT_COMPLETE:
    case RESET_REDUX:
      return INIT_STATE;

    default:
      return state;
  }
};

export default favouriteLists;
