import {
  STORE_QUESTION, VIEW_ARTICLE, NAVIGATION_DETAILS
} from '../actions';

/**
 * Action creator for sending ask request.
 * @returns {object} - action
 */
export function storeQuestion(path) {
  return {
    type: STORE_QUESTION,
    payload: path
  };
}

/**
 * Action creator to view the article.
 * @returns {object} - action
 */
export function viewArticle(path) {
  return {
    type: VIEW_ARTICLE,
    payload: path
  };
}

/**
 * Action creator to fetch the navigation details
 * @returns {object} - action
 */
export function getNavigationDetails() {
  return {
    type: NAVIGATION_DETAILS
  };
}
