import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AskAnswer from './AskAnswer/AskAnswer';
import Button from '../../Button/Button';
import './AskAnswerList.scss';
import {
  viewArticle
} from '../../../actions/navigationDetails/navigationDetails';

/**
 * The list containing all the results gathered pertaining to the users query.
 */
class AskAnswerList extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      compareDisabled: true,
      numberResults: this.props.results.showedMore === true ? 20 : 10
    };
    this.addChecked = this.addChecked.bind(this);
    this.removeChecked = this.removeChecked.bind(this);
    this.compare = this.compare.bind(this);
    this.showArticle = this.showArticle.bind(this);
    this.showMore = this.showMore.bind(this);
  }

  /**
   * Sets the initial state of the compare button
   */
  componentDidMount() {
    const { checked } = this.props;
    this.setState({
      compareDisabled: checked.length <= 1
    });
  }

  /**
   * Adds an answer to the selected list.
   * @param {number} id - id of the selected answer
   */
  addChecked(id) {
    const { check, checked } = this.props;
    check(id);
    this.setState({
      compareDisabled: checked.length <= 1
    });
  }

  /**
   * Removes an answer from the selected list.
   * @param {number} id - id of the un-selected answer
   */
  removeChecked(id) {
    const { uncheck, checked } = this.props;
    uncheck(id);
    this.setState({
      compareDisabled: checked.length - 1 <= 1
    });
  }

  /**
   * Triggers the compare functionality on the ask page.
   * Triggers by navigating to a new URL with the selected answer IDs as a parameter.
   */
  compare() {
    const { history, checked, viewArticle } = this.props;
    const ids = checked.join(',');
    ReactGA.event({
      category: 'Ask',
      action: 'compare'
    });
    ReactGA4.event('compare-article');

    const urlId = encodeURIComponent(ids);
    viewArticle(`/view-articles/${urlId}`);
    history.push(`/view-articles/${urlId}`)
  }

  /**
   * Shows the selected article. Navigates to a new URL that displays the article.
   * @param {number} id - selected article ID
   */
  showArticle(id) {
    const { history, viewArticle } = this.props;
    const urlId = encodeURIComponent(id);
    viewArticle(`/view-articles/${urlId}`);
    history.push(`/view-articles/${urlId}`)
  }

  /**
   * Updates state to show more results.
   */
  showMore() {
    const { results, showedMore } = this.props;
    ReactGA.event({
      category: 'Ask',
      action: 'show-more'
    });
    ReactGA4.event('show-more');
    this.setState({
      numberResults: 20
    });
    if (results.showedMore === false) {
      const answers = results.answers.slice(10, 20);
      answers.map((answer) => {
        ReactGA.event({
          category: 'Ask-Answer',
          action: results.question,
          label: answer.bookInfo ? `${answer.bookInfo[0].id}` : '-1'
        });
        ReactGA4.event('ask-answer', {
          question: results.question,
          article: answer.id,
        });
        return true;
      });
      showedMore();
    }
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { results, checked } = this.props;
    const { numberResults, compareDisabled } = this.state;

    let resultsRender = (
      <div className="pt-4 d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );

    let showMoreButton;
    if (results) {
      if (numberResults === 10) {
        showMoreButton = (
          <div className="text-center pt-4">
            <Button
              onClick={this.showMore}
              customContent="Show More +"
              variant="primary-darken"
              type="button"
              height="36px"
              width="12rem"
            />
          </div>
        );
      }
      const answers = results.answers.slice(0, numberResults);
      resultsRender = answers.map((answer) => (
        < AskAnswer
          data={answer}
          selected={checked.includes(answer.id)}
          addChecked={this.addChecked}
          removeChecked={this.removeChecked}
          showArticle={this.showArticle}
          key={answer.id}
        />
      ));
    }

    return (
      <div className="askAnswerList">
        <div className="askAnswerList_header">
          <h2 className="askAnswerList_title">Results</h2>
          <Button
            onClick={this.compare}
            customContent="Compare"
            variant="secondary-darken"
            disabled={compareDisabled}
            customVariant="button_compare_disabled"
            height="2.5rem"
            width="6rem"
          />
        </div>
        <div className="askAnswerList_list">
          {resultsRender}
          {showMoreButton}
        </div>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    // the below items are available in props and not states
    loading: state.favouriteLists.loading,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    viewArticle: viewArticle
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AskAnswerList);

AskAnswerList.propTypes = {
  check: Proptypes.func.isRequired,
  showedMore: Proptypes.func.isRequired,
  uncheck: Proptypes.func.isRequired,
  checked: Proptypes.arrayOf(Proptypes.string).isRequired,
  results: Proptypes.oneOfType([
    Proptypes.bool,
    Proptypes.shape({
      answers: Proptypes.arrayOf(
        Proptypes.shape({
          id: Proptypes.string,
          docId: Proptypes.string,
          title: Proptypes.string,
          data: Proptypes.string,
          score: Proptypes.number,
          text: Proptypes.string
        })
      ),
      highlighting: Proptypes.arrayOf(Proptypes.string),
      question: Proptypes.string,
      fetched: Proptypes.number
    })
  ]).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired
};
