exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ShareArticleModal{flex-flow:column wrap;align-self:flex-start;margin-top:5%;background:#fff;overflow:auto;border-radius:1.5rem;outline:none;min-width:383.5px;max-width:50%}.ShareArticleModal p{margin-bottom:0}.ShareArticleModal_close_button{display:flex;flex-flow:row nowrap;justify-content:space-around;float:right;margin:0.5rem 1rem 0.5rem 0;font-size:1.5rem;color:#f7b330;cursor:pointer}.ShareArticleModal_header{display:flex;flex-flow:column wrap;margin:2rem 1rem 1rem 1rem;padding:0 20px 0 20px;color:#f7b330}.ShareArticleModal_header_title{font-size:2rem;font-weight:bold}.ShareArticleModal_header_subtitle{font-size:1rem;color:#000}.ShareArticleModal_body{display:flex;flex-flow:column wrap;margin:0 1rem 4rem 1rem;padding:0 20px 0 20px}.ShareArticleModal_body_title{font-size:0.9rem;font-style:italic}.ShareArticleModal_body_message{font-size:1rem;margin-bottom:0}.ShareArticleModal_overlay{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);overflow-y:auto;display:flex;flex-flow:row nowrap;justify-content:center}.ShareArticleModal_parent{overflow:hidden}button{border:0.1rem solid transparent;background-color:transparent}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};