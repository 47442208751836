exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".favourite-item{display:flex;flex-flow:row nowrap;border-bottom:1px solid #ececec;padding:0 1rem}.favourite-item_interactive{display:flex;flex-flow:row;margin-left:1rem;cursor:pointer;padding:1rem 0;width:100%;border-bottom:1px solid #ececec;padding:1rem}.favourite-item_interactive_leftCol{width:100%}.favourite-item_title{color:#003854;font-size:large;font-weight:500}.favourite-item_author{font-size:16px}.favourite-item_author a{color:#2E2EFF}.favourite-item_text{overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.favourite-item_text .truncate-overflow::before{position:absolute;content:\"...\";inset-block-end:0;inset-inline-end:0}.favourite-item_text .truncate-overflow::after{content:\"\";position:absolute;inset-inline-end:0;width:1rem;height:1rem;background:white}.favourite-item_arrow{font-size:40px;color:#f8992d;opacity:1}.favourite-item:hover{background-color:#ececec}.remove_icon{display:flex;flex-flow:row;justify-content:flex-end;align-items:end}.favourite-item_rightCol{display:flex;flex-flow:row;margin-left:1rem;align-self:center;max-width:2rem;align-items:center}.favourite-item_interactive:hover{background-color:#ececec;opacity:1}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};