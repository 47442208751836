exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".askAnswerList{display:flex;flex-flow:column;flex:1 1 100%;max-width:100%;width:100%;justify-content:center;align-self:center;padding:0 1rem}@media (min-width: 900px){.askAnswerList{flex:1 1 70%;max-width:70%;width:70%}}.askAnswerList_header{width:100%;border-bottom:1px solid #ececec;padding-bottom:1rem}.askAnswerList_list{width:100%}.askAnswerList_title{color:#006281;text-align:center;margin-top:1.5rem;margin-bottom:0rem}.askAnswerList_button{text-align:center;padding-top:1.5rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};