exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".publisher{display:flex;flex-flow:column nowrap;flex:1 0 auto;justify-content:center;padding:0 16%}.publisher_title{text-align:center;font-size:2.5rem;font-weight:bold;letter-spacing:1px;margin-bottom:2rem}.publisher a{color:#2E2EFF}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};