import axios from 'axios';

/**
 * Injects the base url into axios requests
 * a post request to http://localhost:36080/login would look like:
 * Request({
        method: 'post',
        url: 'login',
        params: {
          user: 'dpollock@lifelearn.com',
          pass: '12345678'
        }
      })
 * @type AxiosInstance
 */

const Request = axios.create({
  baseURL: UM_API,
});

/**
 * Injects error handling to axios requests
 * Shows a notification on error
 */
Request.interceptors.response.use((response) => response, (error) => {
  return Promise.reject(error);
});

export default Request;
