import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Box, Button } from '@mui/material';
import { completedSurvey, sawExpiryWarning } from '../../actions/userUpdates/updateUser';
import {
  checkKeyWords,
  selectArticle,
  unselectArticle,
  showedMore,
  openBestPracticesModalManual,
  closeBestPracticesModalManual,
  closeAndSearch,
} from '../../actions/ask/askActions';
import {
  storeQuestion
} from '../../actions/navigationDetails/navigationDetails';
import AlertModal from '../Modals/AlertModal/AlertModal';
import AskQuestion from './AskQuestion/AskQuestion';
import AskAnswerList from './AskAnswerList/AskAnswerList';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Ask.scss';
import AuthRequest from '../../api/AuthRequest';
import BestPracticesModal from '../Modals/BestPracticesModal/BestPracticesModal';

/**
 * The ask page for Sofie.
 * Uses user queries to look up relevant information.
 */
class Ask extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      showWarningModal: false,
      modalIsOpen: false,
      categories: null,
    };
    this.searchApi = this.searchApi.bind(this);
    this.askQuestion = this.askQuestion.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePopupModal = this.handlePopupModal.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  /**
   * Start with an empty search bar?
   */
  componentDidMount() {
    this.cancelAsk = axios.CancelToken.source();
    const {
      match,
      user,
      history,
      submitSurvey
    } = this.props;

    if (match.params.query) {
      this.searchApi(match.params.query);
    }
    // for purina users check if the completed survey sent them here if so mark it as done.
    if (history.location.search.localeCompare('?done=yes') === 0 && user.accountID === 4) {
      if (!user.tookSurvey) {
        submitSurvey();
      }
    }

    // check if users license is expiring in the next 4 days.
    const expiring = user.expiresIn < 1000 * 60 * 60 * 24 * 5;
    if (!user.expiryWarning && (user.role === 8 || user.role === 9) && expiring) {
      this.setState({
        showWarningModal: true,
      });
    }

    this.getCategories();
  }

  /**
   * Cancels any pending ask requests.
   */
  componentWillUnmount() {
    this.cancelAsk.cancel();
  }

  /**
   * Change popup window state
   */
  handlePopupModal() {
    const { modalIsOpen } = this.state;
    this.setState({
      modalIsOpen: !modalIsOpen
    });
  }

  /**
   * Gets the categories from the API
   */
  getCategories() {
    AuthRequest({
      method: 'get',
      url: 'get-categories'
    }).then((res) => {
      this.setState({
        categories: res.data.categories
      });
    });
  }

  /**
   * Sends users query to backend to be processed by Watson.
   * @param {string} question - users natural language query.
   * @param {number} category - numerical representation of the category
   */
  searchApi(question, category = undefined) {
    const { checkQuestionKeyWords, searchResults } = this.props;

    if (searchResults.current.question
      && searchResults.current.question.localeCompare(question) !== 0) {
      checkQuestionKeyWords(question, this.cancelAsk);
    } else if (!searchResults.current.question) {
      checkQuestionKeyWords(question, this.cancelAsk);
    } else if (category !== undefined && searchResults.category.localeCompare(category) !== 0) {
      checkQuestionKeyWords(question, this.cancelAsk);
    }
  }

  /**
   * Hides the warning modal.
   */
  closeModal() {
    const { sawExpiry } = this.props;
    sawExpiry();
    this.setState({
      showWarningModal: false
    });
  }

  /**
   * Manages users search request.
   * @param {string} question - users natural language query.
   */
  askQuestion(question) {
    const { history, storeQuestionLocal } = this.props;
    storeQuestionLocal(`/ask/${encodeURI(question)}`);
    history.push(`/ask/${encodeURI(question)}`);

    this.searchApi(question);
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      match, history, searchResults, check, uncheck, user, trackShown, showBestPracticesModalManual,
      hideBestPracticesModalManual, hideBestPracticesModalAuto
    } = this.props;

    const {
      showWarningModal, categories
    } = this.state;
    let list;
    if (searchResults.loading) {
      list = (
        <div className="pt-4 d-flex justify-content-center ask_spinner">
          <Spinner animation="border" />
        </div>
      );
    } else if (!searchResults.current.answers || !match.params.query) {
      list = (<div />);
    } else if (searchResults.current.answers.length === 0) {
      list = (
        <div className="ask_nothing">
          Sorry we were unable to find any results. Please ask a different question.
        </div>
      );
    } else {
      list = (
        <div className="d-flex justify-content-center">
          <AskAnswerList
            results={searchResults.current}
            checked={searchResults.checked}
            showedMore={trackShown}
            check={check}
            uncheck={uncheck}
            history={history}
          />
        </div>
      );
    }

    const timeLeft = {
      days: Math.floor(user.expiresIn / (1000 * 60 * 60 * 24)),
      hours: Math.floor((user.expiresIn / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((user.expiresIn / 1000 / 60) % 60),
      seconds: Math.floor((user.expiresIn / 1000) % 60)
    };

    let timeRemaining;
    if (timeLeft.days) {
      timeRemaining = `${timeLeft.days} day${timeLeft.days > 1 ? 's' : ''}`;
    } else {
      timeRemaining = `${timeLeft.hours} hour${timeLeft.hours > 1 ? 's' : ''} and
      ${timeLeft.minutes} minute${timeLeft.minutes > 1 ? 's' : ''}`;
    }

    const message = `Your license will expire in ${timeRemaining}. To renew or buy a license click `;

    return (
      <div className="ask">
        <AlertModal
          isOpen={showWarningModal}
          title="Your License is Expiring Soon!"
          onClose={this.closeModal}
        >
          <p>
            {message}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.lifelearn.com/sofie-buy-now/"
            >
              here
            </a>
            .
          </p>
        </AlertModal>
        {categories && (
          <AskQuestion
            askQuestion={this.askQuestion}
            searchApi={this.searchApi}
            question={match.params.query}
            categories={categories}
          >
            <Box ml={1} mt={2}>
              <Button
                variant="text"
                onClick={showBestPracticesModalManual}
                sx={{
                  color: 'common.white',
                  lineHeight: 1,
                  padding: '0 3px 3px 1px',
                  textTransform: 'none',
                  borderBottom: '1px solid',
                  borderColor: 'common.white',
                  borderRadius: 0,
                  fontStyle: 'italic',
                  fontSize: '16px',
                  fontWeight: 400
                }}
              >
                Sofie Best Search Practices
              </Button>
            </Box>
          </AskQuestion>
        )}
        {typeof searchResults.bestPracticesModalOpen === 'boolean' ? (
          <BestPracticesModal
            open={searchResults.bestPracticesModalOpen}
            closeModalManual={hideBestPracticesModalManual}
            closeModalAuto={hideBestPracticesModalAuto}
            type={searchResults.bestPracticesModalType}
            cancelToken={this.cancelAsk}
            question={match.params.query}
          />
        ) : <></>}
        {list}
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    searchResults: state.searchResults,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkQuestionKeyWords: checkKeyWords,
    trackShown: showedMore,
    check: selectArticle,
    uncheck: unselectArticle,
    submitSurvey: completedSurvey,
    sawExpiry: sawExpiryWarning,
    storeQuestionLocal: storeQuestion,
    showBestPracticesModalManual: openBestPracticesModalManual,
    hideBestPracticesModalManual: closeBestPracticesModalManual,
    hideBestPracticesModalAuto: closeAndSearch,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Ask);

Ask.propTypes = {
  checkQuestionKeyWords: Proptypes.func.isRequired,
  trackShown: Proptypes.func.isRequired,
  check: Proptypes.func.isRequired,
  uncheck: Proptypes.func.isRequired,
  submitSurvey: Proptypes.func.isRequired,
  sawExpiry: Proptypes.func.isRequired,
  storeQuestionLocal: Proptypes.func.isRequired,
  showBestPracticesModalManual: Proptypes.func.isRequired,
  hideBestPracticesModalManual: Proptypes.func.isRequired,
  hideBestPracticesModalAuto: Proptypes.func.isRequired,

  searchResults: Proptypes.shape({
    loading: Proptypes.bool,
    checked: Proptypes.arrayOf(Proptypes.string),
    category: Proptypes.string,
    current: Proptypes.shape({
      answers: Proptypes.arrayOf(
        Proptypes.shape({
          id: Proptypes.string,
          docId: Proptypes.string,
          title: Proptypes.string,
          data: Proptypes.string,
          score: Proptypes.number,
          text: Proptypes.string
        })
      ),
      question: Proptypes.string,
      highlighting: Proptypes.arrayOf(Proptypes.string),
      fetched: Proptypes.number,
    }),
    bestPracticesModalOpen: Proptypes.bool,
    bestPracticesModalType: Proptypes.oneOf([
      'auto',
      'click',
      ''
    ])
  }).isRequired,

  user: Proptypes.shape({
    accountID: Proptypes.number,
    tookSurvey: Proptypes.bool,
    role: Proptypes.number,
    expiresIn: Proptypes.number,
    expiryWarning: Proptypes.bool
  }).isRequired,

  match: Proptypes.shape({
    params: Proptypes.shape({
      query: Proptypes.string
    })
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func,
    location: Proptypes.shape({
      search: Proptypes.string
    })
  }).isRequired
};
