import React from 'react';
import * as Proptypes from 'prop-types';
import './Pagination.scss';
import Button from '../../Button/Button';

/**
 * Handles splitting up content into different pages.
 * @param {object} props - defined in proptypes
 * @returns {*} - pagination
 * @class
 */
const Pagination = (props) => {
  const {
    currentPage, length, prevPage, nextPage, goToPage
  } = props;
  let prevButton = '';
  let nextButton = '';
  const array = [];

  // conditional rendering for previous page button
  if (currentPage !== 0) {
    prevButton = (
      <Button
        onClick={prevPage}
        variant="custom"
        customVariant="pagination_button"
        customContent={<div>&laquo; Prev</div>}
      />
    );
  }

  // conditional rendering for next page button
  if (currentPage !== length - 1) {
    nextButton = (
      <Button
        onClick={nextPage}
        variant="custom"
        customVariant="pagination_button"
        customContent={<div>Next &raquo;</div>}
      />
    );
  }

  for (let i = 0; i < length; i += 1) {
    array.push(i);
  }

  const numberButtons = array.map((element) => {
    if (currentPage === element) {
      return (
        <Button
          onClick={goToPage}
          variant="custom"
          customVariant="pagination_button_active pagination_button"
          customContent={(element + 1).toString()}
          key={element}
          disabled
        />
      );
    }
    return (
      <Button
        onClick={() => { goToPage(element); }}
        variant="custom"
        customVariant="pagination_button"
        customContent={(element + 1).toString()}
        key={element}
      />
    );
  });

  return (
    <div className="row text-center">
      <div className="col">
        {prevButton}
        {numberButtons}
        {nextButton}
      </div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  currentPage: Proptypes.number.isRequired,
  length: Proptypes.number.isRequired,
  prevPage: Proptypes.func.isRequired,
  nextPage: Proptypes.func.isRequired,
  goToPage: Proptypes.func.isRequired,
};
