import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import Button from '../Button/Button';
import AuthRequest from '../../api/AuthRequest';
import ArticleBody from '../ViewArticles/ArticleBody/ArticleBody';
import ShareArticleModal from '../Modals/ShareArticleModal/ShareArticleModal';
import Header from '../Header/Header';
import { addtoFavourite, removeFavourite, getFavouriteList } from '../../actions/addFavourite/addFavourite';
import './FavouritePage.scss';
import NotesModal from '../Modals/Notes/Notes';


/**
 * The favourite Article page
 */
class FavouritePage extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      activeArticle: 0,
      articleData: [],
      shareArticleClicked: false,
      modalIsOpen: false,
      modalIsOpenMobile: false,
      currentSearchPosition: 0,
      searchIconStatus: false,
      headerStatus: false,
      searchValue: '',
      notesModalIsOpen: false,
      notesModalIsOpenMobile: false,
      notesClicked: false,
      favouriteId: '',
      favouriteStatus: true,
      dropdownStatus: '',
      title: '',
      currentSearchValue: ''
    };

    // Bindings for header
    this.listenScrollEvent = this.listenScrollEvent.bind(this);

    // Bindings for sharing articles
    this.handleShareArticleClick = this.handleShareArticleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);

    // Bindings for search feature
    this.changeSearch = this.changeSearch.bind(this);
    this.handlePrevNext = this.handlePrevNext.bind(this);
    this.jumpTo = this.jumpTo.bind(this);
    this.changeSearchIcon = this.changeSearchIcon.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.timer = null;
    this.handleCallback = this.handleCallback.bind(this);
    this.checkKey = this.checkKey.bind(this);

    // Bindings for Notes
    this.handleNotesClick = this.handleNotesClick.bind(this);
    this.closeNotesModal = this.closeNotesModal.bind(this);

    // Binding for Print
    this.handlePrint = this.handlePrint.bind(this);
    this.searchArticle = this.searchArticle.bind(this);

    this.myRef = React.createRef();
    this.fixedSearchRef = React.createRef();
  }

  /**
   * Gets the article data from the API on component load
   */
  componentDidMount() {
    const { match, favouriteLists } = this.props;

    const docIdArray = decodeURIComponent(match.params.ids).split(',');

    if (favouriteLists.message) {
      const answers = favouriteLists.message.answers;
      const favDetails = answers.find((answer) => answer.doc_id === docIdArray[0]);

      // Intialize the data for the API call to get the document
      const data = [
        {
          id: favDetails.enlyton_url,
          docId: favDetails.doc_id,
          isFavorite: true,
        }
      ];

      this.setState({
        favouriteId: favDetails.favourite_id,
        title: favDetails.title,
      }, () => this.fetchDocument(data));
    }
  }

  /**
   * Remove the article data from the API on component load
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  /**
   * Searches for the entered keyword in the
   * specified context on input
   *
   * @param {event} e - event from the input
   */
  onChangeSearch(e) {
    const searchVal = e.target.value;
    this.setState({
      searchValue: searchVal
    });
  }

  /**
   * Listener on the scroll to change header type
   */
  listenScrollEvent() {
    const {
      headerStatus, modalIsOpen, modalIsOpenMobile, notesModalIsOpen, notesModalIsOpenMobile
    } = this.state;
    const fixedSearchRef = this.fixedSearchRef;

    if (!modalIsOpen && !modalIsOpenMobile && !notesModalIsOpen && !notesModalIsOpenMobile) {
      if (headerStatus) {
        if (window.scrollY + 149 < 150) {
          this.setState({
            headerStatus: false
          });
        }
      } else if (window.scrollY >= 150) {
        if (fixedSearchRef.current != null) {
          this.fixedSearchRef.current.focus();
        }
        this.setState({
          headerStatus: true
        });
      }
    }
  }

  /**
   * gets the document from the API
   * @param {Array} data - document information
   */
  fetchDocument(data) {
    // Ajax call to server to get the answer body html to load
    AuthRequest({
      method: 'post',
      url: 'document',
      data: {
        document: data
      },
    }).then((res) => {
      this.setState({
        articleData: res.data.message
      });
    });

    window.addEventListener('scroll', this.listenScrollEvent);
  }

  /**
   * Searches for the entered keyword in the
   * specified context on input
   *
   * @param {string} value - search value
   */
  changeSearch(value) {
    this.setState({
      searchValue: value,
    }, () => {
      this.searchArticle();
    });
  }

  /**
   * Changes the search value and marks matches
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  searchArticle(searchPadding = false) {
    const { searchValue } = this.state;
    const context = document.querySelector('#article_innerHTML_content');
    // eslint-disable-next-line no-undef
    const instance = new Mark(context);

    if (searchValue !== '') {
      const markOptions = {
        done: () => {
          const elm = document.getElementById('article_innerHTML_content');
          this.setState({
            markTag: elm.getElementsByTagName('mark'),
            currentSearchValue: searchValue
          }, () => this.jumpTo(searchPadding));
        }
      };

      const unMarkOptions = {
        done: () => {
          instance.mark(searchValue, markOptions);
        }
      };
      instance.unmark(unMarkOptions);
    } else {
      instance.unmark();
    }
  }

  /**
   * Jumps to the element matching the currentIndex
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  jumpTo(searchPadding = false) {
    const { markTag, currentSearchPosition } = this.state;
    const bodyRect = document.body.getBoundingClientRect();

    if (markTag.length && markTag[currentSearchPosition] !== undefined) {
      const elemRect = markTag[currentSearchPosition].getBoundingClientRect();

      let mobileAdjustment = 0;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && searchPadding) {
        mobileAdjustment = 150;
      }

      const offset = (elemRect.top - bodyRect.top) - 80 - mobileAdjustment;
      window.scrollTo(0, offset);
    }
  }

  /**
   * Next and previous search jump to
   *
   * @param {object} e - the button being pressed
   */
  handlePrevNext(e) {
    const { markTag, currentSearchPosition } = this.state;
    const dataSearch = e.target.getAttribute('data-search');
    let setPostion = 0;
    if (markTag.length) {
      if (dataSearch === 'prev') {
        setPostion = -1;
      } else {
        setPostion = 1;
      }
      setPostion = currentSearchPosition + setPostion;

      if (setPostion < 0) {
        setPostion = markTag.length - 1;
      }
      if (setPostion > markTag.length - 1) {
        setPostion = 0;
      }

      this.setState({ currentSearchPosition: setPostion }, () => this.jumpTo());
    }
  }

  /**
   * Handles changing the dropdown status in the header
   * @param {boolean} data - status of the header dropdown
   */
  handleCallback(data) {
    this.setState({
      dropdownStatus: data
    });
  }

  /**
   * Hides the share article modal.
   */
  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalIsOpenMobile: false
    });
  }

  /**
   * Handler for clicking the share article icon
   * @param {string} location - location of the share article clicked
   */
  handleShareArticleClick(location) {
    const searchVal = '';
    if (location === 'top') {
      this.setState({
        shareArticleClicked: true,
        modalIsOpen: true,
        searchIconStatus: false,
        searchValue: '',
      }, () => this.changeSearch(searchVal));
    } else {
      this.setState({
        shareArticleClicked: true,
        modalIsOpenMobile: true,
        searchIconStatus: false,
        searchValue: '',
      }, () => this.changeSearch(searchVal));
    }
  }

  /**
   * Changes the search icon from ???? to ????
   */
  changeSearchIcon() {
    const { searchIconStatus } = this.state;
    this.setState({
      searchIconStatus: !searchIconStatus
    });
  }

  /**
   * Handles closing the notes modal
   */
  closeNotesModal() {
    this.setState({
      notesModalIsOpen: false,
      notesModalIsOpenMobile: false,
    });
  }

  /**
   * Handler for clicking the notes icon
   * @param {string} modal - location of the modal
   */
  handleNotesClick(modal) {
    const searchVal = '';
    if (modal === 'top') {
      this.setState({
        notesClicked: true,
        notesModalIsOpen: true,
        searchIconStatus: false,
        searchValue: ''
      }, () => this.changeSearch(searchVal));
    } else {
      this.setState({
        notesClicked: true,
        notesModalIsOpenMobile: true,
        searchIconStatus: false,
        searchValue: ''
      }, () => this.changeSearch(searchVal));
    }
  }

  /**
   * Checks if the enter key has been pressed. If so triggers the search function.
   * @param {object} e - event
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  checkKey(e, searchPadding = false) {
    if (e.key === 'Enter') {
      const { markTag } = this.state;
      const { currentSearchValue, searchValue, currentSearchPosition } = this.state;
      if (currentSearchValue !== searchValue) {
        this.searchArticle(searchPadding);
      } else if (markTag.length) {
        let setPosition = currentSearchPosition + 1;

        if (setPosition < 0) {
          setPosition = markTag.length - 1;
        }
        if (setPosition > markTag.length - 1) {
          setPosition = 0;
        }

        if (searchPadding) {
          this.setState({ currentSearchPosition: setPosition }, () => this.jumpTo(searchPadding));
        } else {
          this.setState({ currentSearchPosition: setPosition }, () => this.jumpTo());
        }
      }
    }
  }

  /**
   * Handles the printing
   */
  handlePrint() {
    const searchVal = '';
    this.changeSearch(searchVal);
    this.setState({
      searchIconStatus: false,
      searchValue: ''
    }, () => {
      ReactGA.event({
        category: 'article-view',
        action: 'Print'
      });
      ReactGA4.event('print-article');
      window.print();
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      history, embed, location, user, favouriteLists
    } = this.props;
    const {
      activeArticle, articleData, modalIsOpen, modalIsOpenMobile, searchIconStatus, headerStatus,
      favouriteStatus, notesClicked, notesModalIsOpen, notesModalIsOpenMobile, favouriteId,
      dropdownStatus, title, shareArticleClicked, searchValue
    } = this.state;

    let notes = null;
    if (favouriteId) {
      const favDetails = favouriteLists.message.answers.find(
        (answer) => answer.favourite_id === favouriteId
      );
      notes = (favDetails.notes === null || favDetails.notes.body === null) ? null : favDetails.notes.body;
    }

    if (articleData.length === 0) {
      return (
        <div className="pt-4 d-flex justify-content-center favArticles_spinner">
          <Spinner animation="border" />
        </div>
      );
    }

    const oneArticle = articleData.length <= 1;
    let article = '';
    let details = '';
    let publisherName = '';
    let docId = '';
    let enlytonID = '';
    if (articleData[activeArticle] !== undefined) {
      const bookInfo = articleData[activeArticle].bookInfo[0];
      docId = articleData[activeArticle].docID;
      enlytonID = articleData[activeArticle].enlytonID;
      // remove links from the body that don't point to another website.
      let data = articleData[activeArticle].answerbody.replace(
        /<a\s[^>]*href\s*=\s*"(?!https?:\/\/)[^"]*"[^>]*>.*?<\/a>/ig, (match) => (
          match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
        )
      );
      // remove links for items that point to valid sites but don't have the site in the link text.
      data = data.replace(/<a[\s]+([^>]+)>(?!https?:\/\/|www\.).*?<\/a>/ig, (match) => (
        match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
      ));

      const publisher = bookInfo.publisher_name
        ? `<a rel="noopener noreferrer" target="_blank" href=${bookInfo.website}>
           ${bookInfo.publisher_name}
         </a>`
        : '';
      if (bookInfo) {
        const book = bookInfo.book ? `${bookInfo.book} -` : '';
        const year = bookInfo.year ? `${bookInfo.year}:` : '';

        if (bookInfo.edition) {
          const author = bookInfo.authors ? `${bookInfo.authors.join(', ')}:` : '';
          const edition = bookInfo.edition ? `${bookInfo.edition}:` : '';
          details = `${author} ${book} ${edition} `;
        } else {
          details = `${book} ${year} `;
        }
      }
      const titleEnd = data.indexOf('</h1>');
      data = `${data.slice(0, titleEnd + 5)}<h5>${details}${publisher}</h5>${data.slice(titleEnd + 5)}`;

      publisherName = bookInfo.publisher_name;
      article = (
        <div
          id="article_innerHTML_content"
          ref={this.myRef}
          dangerouslySetInnerHTML={{
            __html: data
          }}
        />
      );
    }

    // view article header
    const headerImage = !searchIconStatus;
    const headerMobile = searchIconStatus ? 'header_top_input' : 'header_top';
    const envelopeIcon = searchIconStatus ? 'favArticles_fixed_envelope_extend' : 'favArticles_fixed_envelope';
    const printIcon = searchIconStatus ? 'fa fa-print favArticles_fixed_print_extend' : 'fa fa-print favArticles_fixed_print';

    // search section class name
    const searchHeaderIcon = searchIconStatus ? 'fav_search_icon_disable' : 'fav_search_icon';
    const searchIconExtend = favouriteStatus ? '' : 'fav_search_icon_favExtend';
    const searchIcon = `${searchHeaderIcon} ${searchIconExtend}`;
    const searchInput = searchIconStatus ? 'favArticles_fixed_searchInput' : 'favArticles_fixed_searchInput_disable';

    // search input box large screen header
    const searchHeaderInputBox = searchIconStatus ? 'fav_search_input_box' : 'fav_search_disable';
    const searchInputBoxExtend = favouriteStatus ? '' : 'fav_search_input_box_favExtend';
    const searchInputBox = `${searchHeaderInputBox} ${searchInputBoxExtend}`;

    // search mobile version
    const searchIconMobileHeader = searchIconStatus ? 'fav_search_mobile_disable' : 'fav_search_mobile';
    const searchMobileInputHeader = searchIconStatus ? 'fav_search_mobile_input' : 'fav_search_mobile_input_disable';
    const searchDropdown = dropdownStatus ? 'fav_search_mobile_dropdown' : '';
    const searchInputDropdown = dropdownStatus ? 'fav_search_mobile_dropdown_input' : '';

    const searchMobileInput = (modalIsOpen || notesModalIsOpen || notesModalIsOpenMobile || modalIsOpenMobile) ? 'fav_search_mobile_disable' : `${searchMobileInputHeader} ${searchInputDropdown}`;
    const searchIconMobile = (modalIsOpen || notesModalIsOpen || notesModalIsOpenMobile || modalIsOpenMobile) ? 'fav_search_mobile_disable' : `${searchDropdown} ${searchIconMobileHeader}`;

    // notes icon
    const notesHeaderIcon = notes ? 'favourite_notes_icon_active' : 'favourite_notes_icon_inactive';
    const notesSearchExtend = searchIconStatus ? 'favourite_notes_icon_sExtend' : '';
    const notesIcon = `${notesHeaderIcon} ${notesSearchExtend}`;

    return (
      <div className="page_container">
        {/* Header */}
        <div className={headerMobile}>
          {!headerStatus ? (
            <Header
              user={user}
              embed={embed}
              history={history}
              location={location}
              headerImage={headerImage}
              activeFunction
              parentCallback={this.handleCallback}
            />
          ) : ''}
        </div>
        <div className="favArticles_container">
          {/* buttons fixed at top */}
          <div className={classNames(
            { favArticles_header_fixed: !headerStatus },
            { favArticles_header_fixed_scroll: headerStatus },
            { headerFixed: searchIconStatus && !headerStatus },
            { favArticles_header_fixed_scroll_dropdown: dropdownStatus },
            { favArticles_header_fixed_scroll_input: searchIconStatus && dropdownStatus },
            { favArticles_header_popup: modalIsOpen || notesModalIsOpenMobile || modalIsOpenMobile }
          )}
          >
            <Button
              onClick={() => { history.goBack(); }}
              customContent={<i className="fas fa-chevron-left favArticles_fixed_back_icon" />}
              variant="custom"
              customVariant="favArticles_fixed_back"
              type="button"
            />
            <Button
              onClick={() => this.handleNotesClick('banner')}
              customContent={<i className="fa fa-sticky-note" />}
              variant="custom"
              customVariant={notes ? 'favourite_notes_icon_mobile_active' : 'favourite_notes_icon_mobile_inactive'}
              type="button"
            />
            {
              notesClicked
                ? (
                  <NotesModal
                    isOpen={notesModalIsOpenMobile}
                    onClose={this.closeNotesModal}
                    favouriteId={favouriteId}
                  />
                ) : null
            }
            <Button
              onClick={() => this.handleShareArticleClick('banner')}
              customContent={<i className="fa fa-envelope" />}
              variant="custom"
              customVariant={envelopeIcon}
              type="button"
            />
            {
              shareArticleClicked
                ? (
                  <ShareArticleModal
                    isOpen={modalIsOpenMobile}
                    title={title}
                    subtitle={details + publisherName}
                    onClose={this.closeModal}
                    docId={docId}
                    enlytonID={enlytonID}
                  />
                ) : null
            }

            <Button
              onClick={this.handlePrint}
              customContent={<i className={printIcon} />}
              variant="custom"
              customVariant="favArticles_fixed_print"
              type="button"
            />
            <Button
              onClick={this.changeSearchIcon}
              customContent={(
                <i className={classNames('fas fa-search search_icon_large_screen',
                  { favArticles_fixed_search: !searchIconStatus },
                  { favArticles_fixed_search_disable: searchIconStatus })}
                />
              )}
              type="button"
              variant="custom"
              customVariant="favArticles_fixed_search"
            />
            <div className={searchInput}>
              <input
                type="search"
                id="search_article_fixed"
                ref={this.fixedSearchRef}
                onChange={this.onChangeSearch}
                placeholder="Search Header"
                className="search_input_border"
                value={searchValue}
                onKeyPress={this.checkKey}
              />
              <button type="button" data-search="next" onClick={this.handlePrevNext}>
                &darr;
              </button>
              <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                &uarr;
              </button>
              <Button
                customContent={<i className="fas fa-search search_icon_large_screen" />}
                type="button"
                onClick={this.changeSearchIcon}
                variant="custom"
                customVariant="button"
              />
            </div>
          </div>
          <div className="favArticles" id="viewArticles">
            <div className="favArticles_row">
              {/* Mobile version */}
              <Button
                onClick={this.changeSearchIcon}
                customContent={<i className="fas fa-search search_icon_large_screen" />}
                type="button"
                variant="custom"
                customVariant={searchIconMobile}
              />
              <div className={searchMobileInput}>
                <input
                  type="search"
                  id="search_article_mobile"
                  onChange={this.onChangeSearch}
                  placeholder="Search"
                  className="search_input_border"
                  value={searchValue}
                  onKeyPress={(e) => this.checkKey(e, true)}
                />
                <button type="button" data-search="next" onClick={this.handlePrevNext}>
                  &darr;
                </button>
                <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                  &uarr;
                </button>
                <Button
                  customContent={<i className="fas fa-search search_icon_large_screen" />}
                  type="button"
                  onClick={this.changeSearchIcon}
                  variant="custom"
                  customVariant="button"
                />
              </div>
              <div className={classNames(
                'favArticles_header', { favArticles_header_compare: !oneArticle },
                { favArticles_header_one: oneArticle }, { favArticles_header_top: !headerStatus },
                { favArticles_header_top_scroll: headerStatus }
              )}
              >
                {/* Large Screen header top  */}
                <div className="favArticles_header_buttons">
                  <Button
                    onClick={() => { history.goBack(); }}
                    customContent={<i className="fas fa-chevron-left favArticless_back_icon" />}
                    variant="secondary-darken"
                    customVariant="favArticles_back"
                    type="button"
                    height="2.5rem"
                    width="4rem"
                  />

                </div>
                <div className={searchIcon}>
                  <Button
                    onClick={this.changeSearchIcon}
                    customContent={<i className="fas fa-search search_icon_large_screen" />}
                    type="button"
                    variant="custom"
                    customVariant="search_button"
                  />
                </div>
                <div className={searchInputBox}>
                  <Button
                    customContent={<i className="fas fa-search search_icon_large_screen" />}
                    type="button"
                    onClick={this.changeSearchIcon}
                    variant="custom"
                    customVariant="button"
                  />
                  <input
                    type="search"
                    id="search_article_main"
                    onChange={this.onChangeSearch}
                    placeholder="Search"
                    className="search_input_border"
                    value={searchValue}
                    onKeyPress={this.checkKey}
                  />
                  <button type="button" data-search="next" onClick={this.handlePrevNext}>
                    &darr;
                  </button>
                  <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                    &uarr;
                  </button>
                </div>
                {/*      notes           */}

                <Button
                  onClick={() => this.handleNotesClick('top')}
                  customContent={<i className="fa fa-sticky-note" />}
                  variant="custom"
                  customVariant={notesIcon}
                  type="button"
                />
                {
                  notesClicked
                    ? (
                      <NotesModal
                        isOpen={notesModalIsOpen}
                        onClose={this.closeNotesModal}
                        favouriteId={favouriteId}
                      />
                    ) : null
                }

                <div className="favArticles_iconButton">
                  <Button
                    onClick={() => this.handleShareArticleClick('top')}
                    customContent={<i className="fa fa-envelope" />}
                    variant="grey-darken"
                    customVariant="button_icon"
                    type="button"
                  />

                  {
                    shareArticleClicked
                      ? (
                        <ShareArticleModal
                          isOpen={modalIsOpen}
                          title={title}
                          subtitle={details + publisherName}
                          onClose={this.closeModal}
                          docId={docId}
                          enlytonID={enlytonID}
                        />
                      )
                      : null
                  }

                  <Button
                    onClick={this.handlePrint}
                    customContent={<i className="fa fa-print" />}
                    variant="custom"
                    customVariant="button_fav_print"
                    type="button"
                  />
                </div>
              </div>
            </div>
            <div className="favArticles_row">
              <div className={classNames(
                'favArticles_content', { favArticles_contentOne: oneArticle }
              )}
              >
                <ArticleBody data={article} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    //    getFavouriteList: getFavouriteList,
    user: state.user,
    favouriteLists: state.favouriteLists.currentList,
    loadingStatus: state.favouriteLists.loading,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addFavouriteArticle: addtoFavourite,
    removeFavouriteArticle: removeFavourite,
    getFavouriteList,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouritePage);

FavouritePage.propTypes = {
  history: Proptypes.shape({
    push: Proptypes.func,
    goBack: Proptypes.func
  }).isRequired,
  embed: Proptypes.bool.isRequired,
  location: Proptypes.string.isRequired,
  user: Proptypes.shape({}).isRequired,
  match: Proptypes.shape({
    params: Proptypes.shape({
      ids: Proptypes.string
    })
  }).isRequired,
  favouriteLists: Proptypes.shape({
    message: Proptypes.shape({
      answers: Proptypes.arrayOf(Proptypes.shape({}))
    })
  }).isRequired
};
