import React, { Component } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from '../Pagination/Pagination';
import BlogModal from '../../Modals/BlogModal/BlogModal';
import BlogPost from './BlogPost/BlogPost';
import './BlogSection.scss';

/**
 * Generates the blogs section for the publisher page.
 * Shows blog posts made by lifelearn.
 */
class BlogSection extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      blogs: '',
      modalOpen: false,
      currentBlog: 0,
      loading: true
    };
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  /**
   * Retrieves all the blogs.
   */
  componentDidMount() {
    axios({
      method: 'get',
      url: LIFELEARN_BLOG,
    }).then((res) => {
      const blogsChunked = [];
      while (res.data.length > 0) {
        blogsChunked.push(res.data.splice(0, 6));
      }

      this.setState(
        {
          blogs: blogsChunked,
          loading: false
        }
      );
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err.response);
      this.setState({
        loading: false
      });
    });
  }

  /**
   * Changes the page to the next page.
   */
  nextPage() {
    const { currentPage, blogs } = this.state;
    if (currentPage === blogs.length - 1) {
      this.setState({ currentPage: 0 });
    } else {
      this.setState({ currentPage: currentPage + 1 });
    }
  }

  /**
   * Changes the page to the previously page.
   */
  prevPage() {
    const { currentPage, blogs } = this.state;
    if (currentPage === 0) {
      this.setState({ currentPage: blogs.length - 1 });
    } else {
      this.setState({ currentPage: currentPage - 1 });
    }
  }

  /**
   * Jumps to the page entered by the user.
   * @param {number} page - page number
   */
  goToPage(page) {
    this.setState({
      currentPage: page
    });
  }

  /**
   * Toggles if the modal is open or closed.
   * @param {number} id - id
   */
  toggleModal(id) {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen,
      currentBlog: id,
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      currentPage, modalOpen, currentBlog, blogs, loading
    } = this.state;
    let modal = '';

    if (loading) {
      return (
        <div className="blogSection">
          <div className="blogSection_title">
            Sofie Blogs
          </div>
          <div className="blogSection_loader_title">
            Fetching Blog Posts
          </div>
          <div className="blogSection_loader">
            <Spinner animation="border" />
          </div>
        </div>
      );
    }

    if (!blogs) {
      return (
        <div className="blogSection">
          <div className="blogSection_title">
            Sofie Blogs
          </div>
          <div className="blogSection_loader_title">
            Unable to fetch any blog posts.
          </div>
        </div>
      );
    }

    if (modalOpen) {
      modal = (
        <BlogModal
          isOpen={modalOpen}
          title={blogs[currentPage][currentBlog].title.rendered}
          content={blogs[currentPage][currentBlog].content.rendered}
          onCancel={this.toggleModal}
          /* eslint-disable no-underscore-dangle */
          imageAlt={blogs[currentPage][currentBlog]._embedded['wp:featuredmedia'][0].alt_text}
          imageSrc={blogs[currentPage][currentBlog]._embedded['wp:featuredmedia'][0].source_url}
          date={new Date(Date.parse(blogs[currentPage][currentBlog].date)).toLocaleString(undefined, { dateStyle: 'long' })}
        />
      );
    }

    const currentBlogs = blogs[currentPage].map((blog, index) => {
      const excerpt = blog.excerpt.rendered.substr(0, blog.excerpt.rendered.indexOf('&#8230; <a') + 7);
      const imageSrc = blog._embedded['wp:featuredmedia'][0].source_url;
      const imageAlt = blog._embedded['wp:featuredmedia'][0].alt_text;
      const date = new Date(Date.parse(blog.date)).toLocaleString(undefined, { dateStyle: 'long' });
      return (
        <BlogPost
          key={blog.id}
          date={date}
          excerpt={excerpt}
          imageAlt={imageAlt}
          imageSrc={imageSrc}
          title={blog.title.rendered}
          viewArticle={this.toggleModal}
          id={index}
        />
      );
    });

    return (
      <div className="blogSection">
        <div className="blogSection_title">
          Sofie Blogs
        </div>
        <div className="d-flex justify-content-center" style={{ minHeight: '1px', width: '100%' }}>
          <div className="blogSection_blogs">
            {currentBlogs}
          </div>
        </div>
        <Pagination
          length={blogs.length}
          currentPage={currentPage}
          prevPage={this.prevPage}
          nextPage={this.nextPage}
          goToPage={this.goToPage}
        />
        {modal}
      </div>
    );
  }
}

export default BlogSection;
