exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".pagination_button{background-color:#fff;border:none;margin:0 5px;line-height:1.3rem}.pagination_button:hover{background-color:#008b00;color:#fff}.pagination_button:after{content:\"\";display:block;margin:0 auto 0 -5%;width:110%;border-bottom:1px solid #000}.pagination_button_active{cursor:default !important;border-radius:50%;background-color:#008b00;color:#fff}.pagination_button_active:after{border:none}.pagination_button_active:hover{font-weight:normal}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};