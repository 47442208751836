exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".journals{display:flex;flex-flow:column nowrap;width:100%;justify-content:center;align-self:center;margin-bottom:3rem}.journals_title{color:#f8992d;text-align:center;font-size:2rem;font-weight:500;padding:1rem 0 2rem 0;border-top:4px solid #ccc;letter-spacing:1px}.journals_journals{display:flex;flex-flow:row wrap;flex:1;align-self:center;justify-content:center}.journals_journals_journal{display:flex;flex-flow:column nowrap;flex:0 1 30%;margin:0 1rem 3rem 1rem;max-width:33.3333%;min-width:14rem;height:14rem}.journals_journals_journal_title{text-align:center;margin-bottom:1.5rem}.journals_journals_journal_image{max-width:14rem;max-height:14rem}.journals_journals_journal_holder{display:flex;flex-flow:row nowrap;justify-content:center;width:100%;height:100%}.journals_loader{color:#f8992d;align-self:center;padding-top:2rem;margin-bottom:3rem}.journals_loader_title{text-align:center;font-size:xx-large;font-weight:500;margin-top:3rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};