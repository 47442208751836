exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".Header{width:100%}.Header_links{margin-left:auto}.Header .img-responsive{cursor:pointer}.Header_text{padding:0.5rem 1rem 0.5rem 0;border-bottom:5px solid transparent;margin:0;align-self:center;font-size:large;font-weight:500}.Header_divider{padding:0.5rem 0}.Header_image{border:none;background-color:#fff;display:block}@media only screen and (max-width: 900px){.Header_image_disable{display:none}}.Header_button{padding:0.5rem 2rem;border:none;border-bottom:5px solid transparent;color:#000;background-color:#fff;font-size:large}.Header_button:hover{border-bottom:5px solid #f8992d}.Header_button:focus{outline:none}.Header_active{padding:0.5rem 2rem;border:none;border-bottom:5px solid #f8992d;color:#000;font-size:large;background-color:#fff;margin:0 1rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};