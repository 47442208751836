import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Validation from '../../../helpers/Validation';
import Button from '../../Button/Button';
import './AskQuestion.scss';
import {
  updateCategoryDropDown
} from '../../../actions/ask/askActions';
import { getFavouriteList } from '../../../actions/addFavourite/addFavourite';

/**
 * Renders the ask question search box for the Sofie ask page.
 */
class AskQuestion extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      question: '',
      questionField: 'question'
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkKey = this.checkKey.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
  }

  /**
   * Initializes state.
   */
  componentDidMount() {
    const { question, getFavourites, favsLoaded } = this.props;
    if (!favsLoaded) {
      // Call the redux action to get all favourites from the store
      getFavourites();
    }

    this.setState({
      question
    });
  }

  /**
   * Logic for if should component update.
   * @param {object} nextProps - next renders props
   * @param {object} nextState - next renders state
   * @param {object} nextContext - next renders context
   * @returns {boolean} - should the component update
   */
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { question } = this.state;
    const { searchResults } = this.props;
    return question !== nextState.question
      || searchResults.category !== nextProps.searchResults.category;
  }

  /**
   * Kills window on pop state.
   */
  componentWillUnmount() {
    window.onpopstate = () => { };
  }

  /**
   * Handles changes to dropdown list.
   * @param {object} event - event that contains the data
   */
  handleDropdown(event) {
    const { changeCategoryDropdown, searchApi } = this.props;
    const { question } = this.state;

    changeCategoryDropdown(event.target.value);
    if (question) {
      ReactGA.event({
        category: 'Ask',
        action: 'Change-Category',
        label: event.target.value
      });
      ReactGA4.event('change-category', {
        category: event.target.value,
      });
      searchApi(question, event.target.value);
    }
  }

  /**
   * Handles changes to the users query.
   * @param {object} event - event that contains the data
   */
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  /**
   * Validates then submits NL question.
   */
  handleSubmit() {
    const { askQuestion } = this.props;
    const { questionField, question } = this.state;
    const validationObject = [
      {
        name: questionField,
        value: question,
        validationType: 'required'
      }];
    const errors = Validation.formValidation(validationObject);

    if (Object.keys(errors).length === 0) {
      ReactGA.event({
        category: 'Ask',
        action: 'query',
        label: question
      });
      ReactGA4.event('ask-query', {
        question,
      });
      askQuestion(question);
    }
  }

  /**
   * Checks if the enter key has been pressed. If so triggers the search function.
   * @param {object} e - event
   */
  checkKey(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { question } = this.state;
    const { children, categories, searchResults } = this.props;

    return (

      <div className="ask-question">
        <h1 className="ask-question_header">
          Ask Sofie a Question
        </h1>
        <div className="ask-question_row">
          <div className="ask-question_column col-lg-9 data-hj-allow">
            <div className="ask-question_search_section">
              <div className="ask-question_search_dropdown">
                <select
                  name="animals"
                  id="animals"
                  className="ask-question_search_animals"
                  value={searchResults.category}
                  onChange={this.handleDropdown}
                >
                  {
                    categories.map((category) => (
                      <option value={category.id} key={category.id}>{category.name}</option>
                    ))
                  }
                </select>
              </div>
              <input
                type="text"
                name="question"
                placeholder=""
                value={question}
                onChange={this.handleChange}
                className="ask-question_input"
                onKeyDown={this.checkKey}
                data-hj-allow
              />
            </div>
            <div>
              {children}
            </div>
          </div>
          <Button
            onClick={this.handleSubmit}
            customContent="Ask Sofie"
            variant="custom"
            type="submit"
            customVariant="ask-question_button"
          />
        </div>
      </div>

    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    searchResults: state.searchResults,
    favouriteLists: state.favouriteLists.currentList,
    favsLoaded: state.favouriteLists.loaded,
    bestPracticesIgnored: state.user.bestPracticesIgnored
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeCategoryDropdown: updateCategoryDropDown,
    getFavourites: getFavouriteList
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AskQuestion);

AskQuestion.propTypes = {
  question: Proptypes.string,
  askQuestion: Proptypes.func.isRequired,
  changeCategoryDropdown: Proptypes.func.isRequired,
  searchApi: Proptypes.func.isRequired,
  getFavourites: Proptypes.func.isRequired,
  children: Proptypes.node.isRequired,
  categories: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
      name: Proptypes.string,
    })
  ).isRequired,
  favouriteLists: Proptypes.shape({
    message: Proptypes.shape({}),
  }).isRequired,
  favsLoaded: Proptypes.bool.isRequired,
  searchResults: Proptypes.shape({
    category: Proptypes.string,
  }).isRequired,
};

AskQuestion.defaultProps = {
  question: ''
};
