import React, { Component } from 'react';
import PublicHeader from '../PublicHeader/PublicHeader';
import AuthRequest from '../../api/AuthRequest';
import Spinner from 'react-bootstrap/Spinner';
import ArticleBody from '../ViewArticles/ArticleBody/ArticleBody';
import './PublicArticle.scss';

/**
 * Header Component which loads the sofie logo, welcome message and nav buttons.
 */
class PublicArticle extends Component {

  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      articleData: ''
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    AuthRequest({
      method: 'post',
      url: 'get-public-article',
      data: {
        code: match.params.code
      },
    }).then((res) => {
      this.setState({
        articleData: res.data.message
      });
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      articleData
    } = this.state;

    if (articleData.length === 0) {
      return (
        <div>
          <PublicHeader />
          <div className="pt-4 d-flex justify-content-center viewArticles_spinner">
            <Spinner animation="border" />
          </div>
        </div>
      );
    }
    let details = '';
    let publisherName = '';
    let article = '';
    const bookInfo = articleData.bookInfo[0];

    // remove links from the body that don't point to another website.
    let data = articleData.answerbody.replace(
      /<a\s[^>]*href\s*=\s*"(?!https?:\/\/)[^"]*"[^>]*>.*?<\/a>/ig, (match) => (
        match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
      )
    );
    // remove links for items that point to valid sites but don't have the site in the link text.
    data = data.replace(/<a[\s]+([^>]+)>(?!https?:\/\/|www\.).*?<\/a>/ig, (match) => (
      match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
    ));

    const publisher = bookInfo.publisher_name
      ? `<a rel="noopener noreferrer" target="_blank" href=${bookInfo.website}>
          ${bookInfo.publisher_name}
        </a>`
      : '';
    if (bookInfo) {
      const book = bookInfo.book ? `${bookInfo.book} -` : '';
      const year = bookInfo.year ? `${bookInfo.year}:` : '';

      if (bookInfo.edition) {
        const author = bookInfo.authors ? `${bookInfo.authors.join(', ')}:` : '';
        const edition = bookInfo.edition ? `${bookInfo.edition}:` : '';
        details = `${author} ${book} ${edition} `;
      } else {
        details = `${book} ${year} `;
      }
    }
    const titleEnd = data.indexOf('</h1>');
    data = `${data.slice(0, titleEnd + 5)}<h5>${details}${publisher}</h5>${data.slice(titleEnd + 5)}`;

    publisherName = bookInfo.publisher_name;
    article = (
      <div
        id="article_innerHTML_content"
        ref={this.myRef}
        dangerouslySetInnerHTML={{
          __html: data
        }}
      />
    );
    return (<div>
      <PublicHeader />
      <div className="Article_container">
        <ArticleBody data={article} />
      </div>
    </div>)
  }
}

export default PublicArticle;
