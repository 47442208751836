exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ask-question{display:flex;flex-flow:column nowrap;justify-content:center;background-color:#006281;color:#fff;padding-top:50px;padding-bottom:100px}.ask-question_header{text-align:center;margin-bottom:3rem}.ask-question_row{display:flex;flex-flow:row wrap;justify-content:center;flex:0 1 auto}.ask-question_column.col-lg-9{display:flex;flex-flow:column nowrap;margin-right:0}@media (min-width: 700px){.ask-question_column.col-lg-9{max-width:46%;margin-right:-0.5rem}}@media (min-width: 1200px){.ask-question_column.col-lg-9{max-width:46%;margin-right:-2rem}}.ask-question_input{margin:1rem 0.75rem 0 0;font-size:17px;padding:0.375rem 0.75rem;background-color:#fff;color:#006281;border-radius:0 0.25rem 0.25rem 0;border:none;font-weight:400;width:95%;max-width:95%;min-width:280px}.ask-question_input:focus{background-color:#fff;border:none;color:#006281;box-shadow:none;outline:none}.ask-question_input::-ms-input-placeholder{font-weight:300}.ask-question_input::placeholder{font-weight:300}.ask-question_input::-webkit-input-placeholder{font-weight:300}.ask-question_button{margin:0.9rem 0.75rem 0 0.75rem;padding:0.4rem 3rem;background-color:#ea8008;color:#fff;border:solid 1px #ea8008;height:50%}.ask-question_button:hover{background-color:#b96506;color:#fff;border:solid 1px #b96506}.ask-question_search_section{display:flex;flex-flow:row}@media only screen and (max-width: 900px){.ask-question_search_section{flex-flow:column}}.ask-question_search_dropdown{display:flex;flex-flow:row;width:35%;margin-top:1rem;margin-right:auto;border:0.1rem solid;background-color:#ececec}@media only screen and (max-width: 900px){.ask-question_search_dropdown{width:95%}}@media only screen and (max-width: 700px){.ask-question_search_dropdown{width:60%;margin-left:20%}}.ask-question_search_animals{border:0.1rem solid transparent;background-color:#ececec;width:100%;outline:none}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};