import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { setChecked } from '../../actions/ask/askActions';
import Button from '../Button/Button';
import AuthRequest from '../../api/AuthRequest';
import Highlighter from '../../helpers/Highlighter';
import './ViewArticles.scss';
import ArticleBody from './ArticleBody/ArticleBody';
import ShareArticleModal from '../Modals/ShareArticleModal/ShareArticleModal';
import Synonmy from '../../helpers/Synonmy';
import SynonmyHighLighter from '../../helpers/SynonmyHighLighter';
import Header from '../Header/Header';
import { addtoFavourite, removeFavourite, getFavouriteList } from '../../actions/addFavourite/addFavourite';
import {
  storeQuestion
} from '../../actions/navigationDetails/navigationDetails';

/* eslint-disable react/no-danger */
/**
 * Displays one article or allows the user to compare multiple articles.
 * @param {object} props - defined in proptypes
 * @returns {*} - Compare
 * @class
 */
class ViewArticles extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      activeArticle: 0,
      articleData: [],
      answers: [],
      shareArticleClicked: false,
      modalIsOpen: false,
      modalIsOpenMobile: false,
      markTag: [],
      currentSearchPosition: 0,
      searchIconStatus: false,
      headerStatus: false,
      searchValue: '',
      currentTitle: '',
      dropdownStatus: '',
      currentSearchValue: ''
    };
    this.changeArticle = this.changeArticle.bind(this);

    // Bindings for sharing articles
    this.handleShareArticleClick = this.handleShareArticleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);

    // Bindings for search feature
    this.changeSearch = this.changeSearch.bind(this);
    this.handlePrevNext = this.handlePrevNext.bind(this);
    this.jumpTo = this.jumpTo.bind(this);
    this.changeSearchIcon = this.changeSearchIcon.bind(this);
    this.searchArticle = this.searchArticle.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.timer = null;
    this.handleCallback = this.handleCallback.bind(this);
    this.checkKey = this.checkKey.bind(this);
    // Bindings for header
    this.listenScrollEvent = this.listenScrollEvent.bind(this);

    // Bindings for favourite article
    this.addToFavourite = this.addToFavourite.bind(this);

    // Binding for Print
    this.handlePrint = this.handlePrint.bind(this);

    this.myRef = React.createRef();
    this.fixedSearchRef = React.createRef();
  }

  /**
   * Gets the article data from the API on component load
   */
  componentDidMount() {
    const {
      match, searchResults, checked, setSelectedArticles
    } = this.props;
    const docIds = decodeURIComponent(match.params.ids).split(',');
    const newAnswers = [];

    // verifies that checked IDs match IDs in url if not resets them (used for when users go back).
    if (docIds.length > 1) {
      const compare = docIds.filter((id) => (
        checked.includes(id)
      ));
      if (compare.length !== docIds.length) {
        setSelectedArticles(docIds);
      }
    }

    // creates the data used in the api call.
    const data = docIds.map((id) => {
      let res = {};
      let articleId = '';
      let fID = '';

      let isFavorite;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < searchResults.answers.length; i++) {
        if (searchResults.answers[i].id.localeCompare(id) === 0) {
          res = searchResults.answers[i];
          newAnswers.push({ article: res, rank: i + 1 });
          articleId = searchResults.answers[i].id;
          fID = searchResults.answers[i].favourite_id;
          isFavorite = false;
          if (fID !== '0') {
            isFavorite = true;
          }
          break;
        }
      }

      const nlq = searchResults.question;
      const category = searchResults.category;
      return {
        id: res.docId,
        nlq,
        docId: res.id,
        category,
        articleId,
        fID,
        isFavorite,
      };
    });

    this.setState({
      answers: newAnswers,
    });

    // GA event for viewing the first article
    const book = newAnswers[0].article.bookInfo
      ? parseInt(newAnswers[0].article.bookInfo[0].id, 10) : -1;
    ReactGA.event({
      category: 'article-view',
      action: `${searchResults.question}:${newAnswers[0].rank}:${book}`,
      label: newAnswers[0].article.title,
    });
    ReactGA4.event('article-view', {
      article: newAnswers[0].article.id,
      rank: newAnswers[0].rank,
      question: searchResults.question,
    });

    this.setState({
      currentTitle: newAnswers[0].article.title
    });

    AuthRequest({
      method: 'post',
      url: 'document',
      data: {
        document: data
      },
    }).then((res) => {
      this.setState({
        articleData: res.data.message
      });
    });

    window.addEventListener('scroll', this.listenScrollEvent);
  }

  /**
   * Highlights & Mark the article content after the article is loaded in.
   * @param {object} prevProps - previous props
   * @param {object} prevState - previous state
   */
  componentDidUpdate(prevProps, prevState) {
    const { searchResults } = this.props;
    const {
      articleData, activeArticle
    } = this.state;

    const hotspot = searchResults.highlighting.join();
    const synonmys = Synonmy(searchResults.highlighting);
    if (prevState.articleData.length !== articleData.length
      || prevState.activeArticle !== activeArticle) {
      Highlighter(this.myRef.current, hotspot);
      SynonmyHighLighter(synonmys);
    }
    if (APP_ENV === 'production') {
      // eslint-disable-next-line no-undef
      hj('identify', null, {
        natural_language_question: searchResults.question,
      });
    }
  }

  /**
   * Remove the article data from the API on component load
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  /**
   * Searches for the entered keyword in the
   * specified context on input
   *
   * @param {event} e - event from the input
   */
  onChangeSearch(e) {
    const searchVal = e.target.value;
    this.setState({
      searchValue: searchVal
    });
  }

  /**
   * Changes the article to the supplied index when the button is clicked
   * @param {number} index - integer of the new article to be displayed
   */
  changeArticle(index) {
    const { searchResults } = this.props;
    const { answers } = this.state;
    // GA event for viewing the first article
    const book = answers[index].article.bookInfo
      ? answers[index].article.bookInfo[0].id : -1;
    ReactGA.event({
      category: 'article-view',
      action: `${searchResults.question}:${answers[0].rank}:${book}`,
      label: answers[index].article.title,
    });
    ReactGA4.event('article-view', {
      article: answers[index].article.id,
      rank: answers[0].rank,
      question: searchResults.question,
    });

    this.setState({
      activeArticle: index,
      currentTitle: answers[index].article.title
    }, () => this.searchArticle());
  }

  /**
   * Searches for the entered keyword in the
   * specified context on input
   *
   * @param {string} value - search value
   */
  changeSearch(value) {
    this.setState({
      searchValue: value,
    }, () => {
      this.searchArticle();
    });
  }

  /**
   * Changes the search value and marks matches
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  searchArticle(searchPadding = false) {
    const { searchValue } = this.state;
    const context = document.querySelector('#article_innerHTML_content');
    // eslint-disable-next-line no-undef
    const instance = new Mark(context);

    if (searchValue !== '') {
      const markOptions = {
        done: () => {
          const elm = document.getElementById('article_innerHTML_content');
          this.setState({
            markTag: elm.getElementsByTagName('mark'),
            currentSearchValue: searchValue
          }, () => this.jumpTo(searchPadding));
        }
      };

      const unMarkOptions = {
        done: () => {
          instance.mark(searchValue, markOptions);
        }
      };
      instance.unmark(unMarkOptions);
    } else {
      instance.unmark();
    }
  }

  /**
   * Jumps to the element matching the currentIndex
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  jumpTo(searchPadding = false) {
    const { markTag, currentSearchPosition } = this.state;
    const bodyRect = document.body.getBoundingClientRect();

    if (markTag.length && markTag[currentSearchPosition] !== undefined) {
      const elemRect = markTag[currentSearchPosition].getBoundingClientRect();

      let mobileAdjustment = 0;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && searchPadding) {
        mobileAdjustment = 150;
      }

      const offset = (elemRect.top - bodyRect.top) - 80 - mobileAdjustment;
      window.scrollTo(0, offset);
    }
  }

  /**
   * Checks if the enter key has been pressed. If so triggers the search function.
   * @param {object} e - event
   * @param {boolean} searchPadding - whether to add search padding or not
   */
  checkKey(e, searchPadding = false) {
    if (e.key === 'Enter') {
      const { markTag } = this.state;
      const { currentSearchValue, searchValue, currentSearchPosition } = this.state;
      if (currentSearchValue !== searchValue) {
        this.searchArticle(searchPadding);
      } else if (markTag.length) {
        let setPosition = currentSearchPosition + 1;

        if (setPosition < 0) {
          setPosition = markTag.length - 1;
        }
        if (setPosition > markTag.length - 1) {
          setPosition = 0;
        }

        if (searchPadding) {
          this.setState({ currentSearchPosition: setPosition }, () => this.jumpTo(searchPadding));
        } else {
          this.setState({ currentSearchPosition: setPosition }, () => this.jumpTo());
        }
      }
    }
  }

  /**
   * Next and previous search jump to
   *
   * @param {object} e - the button being pressed
   */
  handlePrevNext(e) {
    const { markTag, currentSearchPosition } = this.state;
    const dataSearch = e.target.getAttribute('data-search');
    let setPosition = 0;
    if (markTag.length) {
      if (dataSearch === 'prev') {
        setPosition = -1;
      } else {
        setPosition = 1;
      }
      setPosition = currentSearchPosition + setPosition;

      if (setPosition < 0) {
        setPosition = markTag.length - 1;
      }
      if (setPosition > markTag.length - 1) {
        setPosition = 0;
      }

      this.setState({ currentSearchPosition: setPosition }, () => this.jumpTo());
    }
  }

  /**
   * Hides the share article modal.
   */
  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalIsOpenMobile: false
    });
  }

  /**
   * Handler for clicking the share article icon
   * @param {string} location - location of the share article clicked
   */
  handleShareArticleClick(location) {
    const searchVal = '';
    if (location === 'top') {
      this.setState({
        shareArticleClicked: true,
        modalIsOpen: true,
        searchIconStatus: false,
        searchValue: '',
      }, () => this.changeSearch(searchVal));
    } else {
      this.setState({
        shareArticleClicked: true,
        modalIsOpenMobile: true,
        searchIconStatus: false,
        searchValue: '',
      }, () => this.changeSearch(searchVal));
    }
  }

  /**
   * Changes the search icon from ???? to ????
   */
  changeSearchIcon() {
    const { searchIconStatus } = this.state;
    this.setState({
      searchIconStatus: !searchIconStatus
    });
  }

  /**
   * Listener on the scroll to change header type
   */
  listenScrollEvent() {
    const {
      headerStatus, modalIsOpen, modalIsOpenMobile, notesModalIsOpen, notesModalIsOpenMobile
    } = this.state;
    const fixedSearchRef = this.fixedSearchRef;

    if (!modalIsOpen && !modalIsOpenMobile && !notesModalIsOpen && !notesModalIsOpenMobile) {
      if (headerStatus) {
        if (window.scrollY + 149 < 150) {
          this.setState({
            headerStatus: false
          });
        }
      } else if (window.scrollY >= 150) {
        if (fixedSearchRef.current != null) {
          this.fixedSearchRef.current.focus();
        }
        this.setState({
          headerStatus: true
        });
      }
    }
  }

  /**
   * Handles changing the dropdown status in the header
   * @param {boolean} data - status of the header dropdown
   */
  handleCallback(data) {
    this.setState({
      dropdownStatus: data
    });
  }

  /**
   * handles the navigating back to ask page
   */
  goBackAsk() {
    const { history, navigationDetails, articleStoreQuestion } = this.props;
    articleStoreQuestion(navigationDetails.question);
    history.push(navigationDetails.question);
  }

  /**
   * @returns {*} -
   * @param id
   */
  addToFavourite(id) {
    const { addFavouriteArticle } = this.props;
    const searchVal = '';
    addFavouriteArticle(id);
    this.setState({
      searchIconStatus: false,
      searchValue: ''
    }, () => this.changeSearch(searchVal));
  }

  /**
   * Handles the printing
   */
  handlePrint() {
    const searchVal = '';
    this.changeSearch(searchVal);
    this.setState({
      searchIconStatus: false,
      searchValue: ''
    }, () => {
      ReactGA.event({
        category: 'article-view',
        action: 'Print'
      });
      ReactGA4.event('print-article');
      window.print();
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const {
      history, location, user, favouriteLists, embed, removeFavouriteArticle
    } = this.props;

    const {
      activeArticle, articleData, answers, modalIsOpen, modalIsOpenMobile, searchIconStatus,
      headerStatus, currentTitle, dropdownStatus, searchValue, shareArticleClicked
    } = this.state;

    if (articleData.length === 0) {
      return (
        <div className="pt-4 d-flex justify-content-center viewArticles_spinner">
          <Spinner animation="border" />
        </div>
      );
    }

    const oneArticle = articleData.length <= 1;
    let article = '';
    let buttons = '';
    let title = '';
    let details = '';
    let publisherName = '';
    let docId = '';
    let enlytonID = '';
    let isFavourite = false;
    if (articleData[activeArticle] !== undefined) {
      const bookInfo = articleData[activeArticle].bookInfo[0];
      docId = articleData[activeArticle].docID;
      enlytonID = articleData[activeArticle].enlytonID;

      if (favouriteLists && favouriteLists.message && favouriteLists.message.answers) {
        for (let i = 0; i < favouriteLists.message.answers.length; i += 1) {
          if (favouriteLists.message.answers[i].doc_id === docId) {
            isFavourite = true;
            break;
          }
        }
      }

      // remove links from the body that don't point to another website.
      let data = articleData[activeArticle].answerbody.replace(
        /<a\s[^>]*href\s*=\s*"(?!https?:\/\/)[^"]*"[^>]*>.*?<\/a>/ig, (match) => (
          match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
        )
      );
      // remove links for items that point to valid sites but don't have the site in the link text.
      data = data.replace(/<a[\s]+([^>]+)>(?!https?:\/\/|www\.).*?<\/a>/ig, (match) => (
        match.replace(/<a\b[^>]*>/ig, '').replace(/<\/a>/ig, '')
      ));

      const publisher = bookInfo.publisher_name
        ? `<a rel="noopener noreferrer" target="_blank" href=${bookInfo.website}>
          ${bookInfo.publisher_name}
        </a>`
        : '';
      if (bookInfo) {
        const book = bookInfo.book ? `${bookInfo.book} -` : '';
        const year = bookInfo.year ? `${bookInfo.year}:` : '';

        if (bookInfo.edition) {
          const author = bookInfo.authors ? `${bookInfo.authors.join(', ')}:` : '';
          const edition = bookInfo.edition ? `${bookInfo.edition}:` : '';
          details = `${author} ${book} ${edition} `;
        } else {
          details = `${book} ${year} `;
        }
      }
      const titleEnd = data.indexOf('</h1>');
      data = `${data.slice(0, titleEnd + 5)}<h5>${details}${publisher}</h5>${data.slice(titleEnd + 5)}`;

      publisherName = bookInfo.publisher_name;
      article = (
        <div
          id="article_innerHTML_content"
          ref={this.myRef}
          dangerouslySetInnerHTML={{
            __html: data
          }}
        />
      );

      buttons = articleData.map((document, index) => {
        title = answers.filter((answer) => (
          answer.article.id.localeCompare(document.docID) === 0
        ))[0].article.title;

        if (index === activeArticle) {
          return (
            <Button
              onClick={() => { this.changeArticle(index); }}
              customContent={<div className="viewArticles_button_text">{title}</div>}
              variant="custom"
              type="button"
              customVariant="viewArticles_active"
              key={document.docID}
              squareButton
            />
          );
        }
        return (
          <Button
            onClick={() => { this.changeArticle(index); }}
            customContent={<div className="viewArticles_button_text">{title}</div>}
            variant="custom"
            type="button"
            customVariant="viewArticles_button"
            key={document.docId}
            squareButton
          />
        );
      });
    }

    // view article header
    const headerImage = !searchIconStatus;
    const headerMobile = searchIconStatus ? 'header_top_input' : 'header_top';
    const envelopeIcon = searchIconStatus ? 'viewArticles_fixed_envelope_extend' : 'viewArticles_fixed_envelope';
    const printIcon = searchIconStatus ? 'fa fa-print viewArticles_fixed_print_extend' : 'fa fa-print viewArticles_fixed_print';
    // search section class name
    const searchHeaderIcon = searchIconStatus ? 'search_icon_disable' : 'search_icon';
    const searchIconExtend = isFavourite ? '' : 'search_icon_favExtend';
    const searchIcon = `${searchHeaderIcon} ${searchIconExtend}`;
    const searchInput = searchIconStatus ? 'viewArticles_fixed_searchInput' : 'viewArticles_fixed_searchInput_disable';
    // search input box large screen header
    const searchHeaderInputBox = searchIconStatus ? 'search_input_box' : 'search_disable';
    const searchInputBoxExtend = isFavourite ? '' : 'search_input_box_favExtend';
    const searchInputBox = `${searchHeaderInputBox} ${searchInputBoxExtend}`;
    // search mobile version
    const searchIconMobileHeader = searchIconStatus ? 'search_mobile_disable' : 'search_mobile';
    const searchMobileInputHeader = searchIconStatus ? 'search_mobile_input' : 'search_mobile_input_disable';
    const searchDropdown = dropdownStatus ? 'search_mobile_dropdown' : '';
    const searchInputDropdown = dropdownStatus ? 'search_mobile_dropdown_input' : '';

    const searchMobileInput = (modalIsOpen || modalIsOpenMobile) ? 'search_mobile_disable' : `${searchMobileInputHeader} ${searchInputDropdown}`;
    const searchIconMobile = (modalIsOpen || modalIsOpenMobile) ? 'search_mobile_disable' : `${searchDropdown} ${searchIconMobileHeader}`;

    // favourite icon
    const favouriteIconMobile = isFavourite ? 'favourite_icon_mobile_selected' : 'favourite_icon_mobile_unselected';
    const favouriteExtend = searchIconStatus ? 'favourite_extend' : '';
    const favourite = `${favouriteIconMobile} ${favouriteExtend}`;

    return (
      <div className="page_container">
        {/* Header */}
        <div className={headerMobile}>
          {!headerStatus ? (
            <Header
              user={user}
              embed={embed}
              history={history}
              location={location}
              headerImage={headerImage}
              activeFunction
              parentCallback={this.handleCallback}
            />
          ) : ''}
        </div>
        <div className="viewArticles_container">
          {/* buttons fixed at top */}
          <div className={classNames(
            { viewArticles_header_fixed: !headerStatus },
            { viewArticles_header_fixed_scroll: headerStatus },
            { headerFixed: searchIconStatus && !headerStatus },
            { viewArticles_header_fixed_scroll_dropdown: dropdownStatus },
            { viewArticles_header_fixed_scroll_input: searchIconStatus && dropdownStatus },
            { viewArticles_header_popup: modalIsOpen || modalIsOpenMobile }
          )}
          >
            <Button
              onClick={() => { this.goBackAsk(); }}
              customContent={<i className="fas fa-chevron-left viewArticles_fixed_back_icon" />}
              variant="custom"
              customVariant="viewArticles_fixed_back"
              type="button"
            />
            {isFavourite ? (
              <Button
                onClick={() => removeFavouriteArticle(docId)}
                customContent={<i className="fas fa-heart" />}
                variant="custom"
                customVariant={favourite}
                type="button"
              />
            ) : (
              <Button
                onClick={() => this.addToFavourite(docId)}
                customContent={<i className="far fa-heart" />}
                variant="custom"
                customVariant={favourite}
                type="button"
              />
            )}
            <Button
              onClick={() => this.handleShareArticleClick('banner')}
              customContent={<i className="fa fa-envelope" />}
              variant="custom"
              customVariant={envelopeIcon}
              type="button"
            />
            {
              shareArticleClicked
                ? (
                  <ShareArticleModal
                    isOpen={modalIsOpenMobile}
                    title={currentTitle}
                    subtitle={details + publisherName}
                    onClose={this.closeModal}
                    docId={docId}
                    enlytonID={enlytonID}
                  />
                ) : null
            }

            <Button
              onClick={this.handlePrint}
              customContent={<i className={printIcon} />}
              variant="custom"
              customVariant="viewArticles_fixed_print"
              type="button"
            />
            <Button
              onClick={this.changeSearchIcon}
              customContent={(
                <i className={classNames('fas fa-search search_icon_large_screen',
                  { viewArticles_fixed_search: !searchIconStatus },
                  { viewArticles_fixed_search_disable: searchIconStatus })}
                />
              )}
              type="button"
              variant="custom"
              customVariant="viewArticles_fixed_search"
            />
            <div className={searchInput}>
              <input
                type="search"
                id="searcharticle"
                ref={this.fixedSearchRef}
                onChange={this.onChangeSearch}
                placeholder="Search"
                className="search_input_border"
                value={searchValue}
                onKeyPress={this.checkKey}
              />
              <button type="button" data-search="next" onClick={this.handlePrevNext}>
                &darr;
              </button>
              <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                &uarr;
              </button>
              <Button
                customContent={<i className="fas fa-search search_icon_large_screen" />}
                type="button"
                onClick={this.changeSearchIcon}
                variant="custom"
                customVariant="button"
              />
            </div>
          </div>
          <div className="viewArticles" id="viewArticles">
            <div className="viewArticles_row">
              {/* Mobile version */}
              <Button
                onClick={this.changeSearchIcon}
                customContent={<i className="fas fa-search search_icon_large_screen" />}
                type="button"
                variant="custom"
                customVariant={searchIconMobile}
              />
              <div className={searchMobileInput}>
                <input
                  type="search"
                  name="searchMobile"
                  id="searchMobile"
                  placeholder="Search"
                  className="search_input_border data-hj-allow"
                  value={searchValue}
                  onChange={this.onChangeSearch}
                  onKeyPress={(e) => this.checkKey(e, true)}
                  data-hj-allow
                />
                <button type="button" data-search="next" onClick={this.handlePrevNext}>
                  &darr;
                </button>
                <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                  &uarr;
                </button>
                <Button
                  customContent={<i className="fas fa-search search_icon_large_screen" />}
                  type="button"
                  onClick={this.changeSearchIcon}
                  variant="custom"
                  customVariant="button"
                />
              </div>

              <div className={classNames(
                'viewArticles_header', { viewArticles_header_compare: !oneArticle },
                { viewArticles_header_one: oneArticle }, { viewArticles_header_top: !headerStatus },
                { viewArticles_header_top_scroll: headerStatus }
              )}
              >
                <div className="viewArticles_header_buttons">
                  <Button
                    onClick={() => { this.goBackAsk(); }}
                    customContent={<i className="fas fa-chevron-left viewArticles_back_icon" />}
                    variant="secondary-darken"
                    customVariant="viewArticles_back"
                    type="button"
                    height="2.5rem"
                    width="4rem"
                  />
                  {isFavourite ? (
                    <Button
                      onClick={() => this.addToFavourite(docId)}
                      customContent={<i className="fas fa-heart" />}
                      variant="custom"
                      customVariant="favourite_selected"
                      type="button"
                      disabled
                    />
                  ) : (
                    <Button
                      onClick={() => this.addToFavourite(docId)}
                      customContent="Add to Favorites"
                      variant="custom"
                      customVariant="favourite_unselected"
                      type="button"
                    />
                  )}
                </div>
                <div className={searchIcon}>
                  <Button
                    onClick={this.changeSearchIcon}
                    customContent={<i className="fas fa-search search_icon_large_screen" />}
                    type="button"
                    variant="custom"
                    customVariant="search_button"
                  />
                </div>
                <div className={searchInputBox}>
                  <Button
                    customContent={<i className="fas fa-search search_icon_large_screen" />}
                    type="button"
                    onClick={this.changeSearchIcon}
                    variant="custom"
                    customVariant="button"
                    onKeyPress={this.checkKey}
                  />
                  <input
                    type="search"
                    id="searcharticle"
                    onChange={this.onChangeSearch}
                    placeholder="Search"
                    className="search_input_border"
                    value={searchValue}
                    onKeyPress={this.checkKey}
                  />
                  <button type="button" data-search="next" onClick={this.handlePrevNext}>
                    &darr;
                  </button>
                  <button type="button" data-search="prev" onClick={this.handlePrevNext}>
                    &uarr;
                  </button>
                </div>
                <div className="viewArticles_iconButton">
                  <Button
                    onClick={() => this.handleShareArticleClick('top')}
                    customContent={<i className="fa fa-envelope" />}
                    variant="grey-darken"
                    customVariant="button_share"
                    type="button"
                  />

                  {
                    shareArticleClicked
                      ? (
                        <ShareArticleModal
                          isOpen={modalIsOpen}
                          title={currentTitle}
                          subtitle={details + publisherName}
                          onClose={this.closeModal}
                          docId={docId}
                          enlytonID={enlytonID}
                        />
                      ) : null
                  }

                  <Button
                    onClick={this.handlePrint}
                    customContent={<i className="fa fa-print" />}
                    variant="custom"
                    customVariant="button_print"
                    type="button"
                  />
                </div>
                {!oneArticle && (
                  <div className="viewArticles_header_text">
                    Sofie Compare
                  </div>
                )}

              </div>
            </div>
            <div className="viewArticles_row">
              {!oneArticle && (
                <div className="viewArticles_left">
                  {buttons}
                </div>
              )}
              <div className={classNames(
                'viewArticles_content', { viewArticles_contentCompare: !oneArticle },
                { viewArticles_contentOne: oneArticle }
              )}
              >
                <ArticleBody data={article} />
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    searchResults: state.searchResults.current,
    checked: state.searchResults.checked,
    user: state.user,
    favouriteLists: state.favouriteLists.currentList,
    loading: state.favouriteLists.loading,
    navigationDetails: state.navigationDetails
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{ setSelectedArticles: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setSelectedArticles: setChecked,
    addFavouriteArticle: addtoFavourite,
    removeFavouriteArticle: removeFavourite,
    getFavouriteList,
    articleStoreQuestion: storeQuestion
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewArticles);

ViewArticles.propTypes = {
  setSelectedArticles: Proptypes.func.isRequired,
  addFavouriteArticle: Proptypes.func.isRequired,
  removeFavouriteArticle: Proptypes.func.isRequired,
  articleStoreQuestion: Proptypes.func.isRequired,
  searchResults: Proptypes.shape({
    answers: Proptypes.arrayOf(
      Proptypes.shape({
        id: Proptypes.string,
        docId: Proptypes.string,
        title: Proptypes.string,
        data: Proptypes.string,
        score: Proptypes.number,
        text: Proptypes.string,
        passage: Proptypes.arrayOf(
          Proptypes.shape({
            start_offset: Proptypes.number,
            end_offset: Proptypes.number,
          })
        ),
        favourite_id: Proptypes.string,
      })
    ),
    question: Proptypes.string,
    highlighting: Proptypes.arrayOf(Proptypes.string),
    fetched: Proptypes.number,
    category: Proptypes.string,
  }).isRequired,
  user: Proptypes.shape({
    firstName: Proptypes.string,
    lastName: Proptypes.string,
    role: Proptypes.number
  }).isRequired,
  checked: Proptypes.arrayOf(Proptypes.string).isRequired,
  history: Proptypes.shape({
    goBack: Proptypes.func,
    push: Proptypes.func,
  }).isRequired,
  match: Proptypes.shape({
    params: Proptypes.shape({
      ids: Proptypes.string,
    }),
  }).isRequired,
  navigationDetails: Proptypes.shape({
    question: Proptypes.string,
  }).isRequired,
  location: Proptypes.string.isRequired,
  favouriteLists: Proptypes.shape({
    message: Proptypes.shape({
      answers: Proptypes.arrayOf(Proptypes.shape({
        doc_id: Proptypes.string
      }))
    })
  }).isRequired,
  embed: Proptypes.bool.isRequired,
};
