import React from 'react';
import * as Proptypes from 'prop-types';
import Button from '../../../Button/Button';
import './BlogPost.scss';


/**
 * Generates the blog posts shown in the blog posts section.
 * @param {object} props - defined in proptypes
 * @returns {*} - blog post
 * @class
 */
const BlogPost = (props) => {
  const {
    date, imageSrc, imageAlt, title, excerpt, viewArticle, id
  } = props;

  /**
   * views the article
   */
  function view() {
    viewArticle(id);
  }

  const indexofSpace = excerpt.indexOf(' ', 140);
  const displayText = `${excerpt.slice(0, indexofSpace)}...`;

  return (
    <div className="blogPost">
      <div className="blogPost_header">
        <p>{date}</p>
      </div>
      <div style={{ minHeight: '1px' }}>
        <img src={imageSrc} alt={imageAlt} className="img-fluid" />
      </div>
      <div className="blogPost_content">
        <h3>{title}</h3>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{
          __html: displayText
        }}
        />
        <p>
          <Button
            onClick={view}
            variant="custom"
            customVariant="blogPost_viewarticle"
            customContent="View Article"
          />
        </p>
      </div>
    </div>
  );
};

export default BlogPost;

BlogPost.propTypes = {
  id: Proptypes.number.isRequired,
  date: Proptypes.string.isRequired,
  imageSrc: Proptypes.string.isRequired,
  imageAlt: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  excerpt: Proptypes.string.isRequired,
  viewArticle: Proptypes.func.isRequired,
};
