import {
  ASK_REQUEST, ASK_SUCCESS, ASK_FAILURE, UPDATE_CACHE, SHOWED_MORE, UPDATE_CATEGORY,
  SELECT_ARTICLE, UNSELECT_ARTICLE, SET_CHECKED,
  LOGOUT_COMPLETE, LOGOUT_INVALID_TOKEN, RESET_REDUX, RESET_SEARCHRESULT
} from '../../actions/actions';
import * as actions from '../../actions/actions';

const INIT_STATE = {
  current: {},
  checked: [],
  cache: [],
  loading: false,
  category: '2',
  bestPracticesModalOpen: false,
  bestPracticesModalType: '',
};

const searchResults = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ASK_REQUEST:
      return {
        ...state,
        checked: [],
        loading: true,
      };

    case ASK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case ASK_FAILURE:
      return {
        ...state,
        loading: false
      };

    case UPDATE_CACHE:
      return {
        ...state,
        cache: action.payload.cache
      };

    case SHOWED_MORE: {
      const newCurrent = state.current;
      newCurrent.showedMore = true;
      return {
        ...state,
        current: newCurrent
      };
    }

    case SELECT_ARTICLE: {
      const newChecked = state.checked;
      newChecked.push(action.payload.id);
      return {
        ...state,
        checked: newChecked
      };
    }

    case UNSELECT_ARTICLE: {
      const newChecked = state.checked.filter((id) => (
        id !== action.payload.id
      ));
      return {
        ...state,
        checked: newChecked
      };
    }

    case SET_CHECKED: {
      return {
        ...state,
        checked: action.payload
      };
    }

    case UPDATE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };

    case actions.OPEN_BEST_PRACTICES_MODAL_MANUAL:
      return {
        ...state,
        bestPracticesModalOpen: true,
        bestPracticesModalType: 'click',
      };

    case actions.CLOSE_BEST_PRACTICES_MODAL_MANUAL:
      return {
        ...state,
        bestPracticesModalOpen: false,
      };

    case actions.OPEN_BEST_PRACTICES_MODAL_AUTO:
      return {
        ...state,
        bestPracticesModalOpen: true,
        bestPracticesModalType: 'auto',
      };

    case actions.CLOSE_BEST_PRACTICES_MODAL_AUTO:
      return {
        ...state,
        bestPracticesModalOpen: false,
      };

    case LOGOUT_INVALID_TOKEN:
    case LOGOUT_COMPLETE:
    case RESET_REDUX:
    case RESET_SEARCHRESULT:
      return INIT_STATE;

    default:
      return state;
  }
};

export default searchResults;
