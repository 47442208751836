import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../Button/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './Notes.scss';
import { bindActionCreators } from 'redux';
import { addNotes } from '../../../actions/addFavourite/addFavourite';
import { connect } from 'react-redux';

// This helps make the modal accessible.
Modal.setAppElement(document.getElementById('sofie-embed'));

class NotesModal extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      localNotes: ''
    }
    this.updateNotes = this.updateNotes.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleNotesClose = this.handleNotesClose.bind(this);
  }

  componentDidMount() {
    this.setNotes();
  }

  setNotes() {
    const { favouriteLists, favouriteId } = this.props
    const answers = favouriteLists.message.answers;
    const { notes } = answers.find(answer => answer.favourite_id === favouriteId);

    this.setState({
      localNotes: notes === null ? '' : notes.body
    })
  }

  updateNotes(e) {
    const wordLength = e.target.value.trim().split(/\s+/).length;
    const { localNotes } = this.state
    this.setState({
      localNotes: wordLength <= 1000 ? e.target.value : localNotes,
    })
  }

  onSubmit() {
    const { addNotes, favouriteId, onClose } = this.props;
    const { localNotes } = this.state
    addNotes(favouriteId, localNotes);
    onClose()
  }

  handleNotesClose() {
    const { onClose } = this.props;
    this.setNotes();
    onClose()
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { isOpen, onClose } = this.props;
    const { localNotes } = this.state
    return (
      <>
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="ShareArticleModal Modal"
          className="ShareArticleModal"
          overlayClassName="ShareArticleModal_overlay"
          bodyOpenClassName="ShareArticleModal_parent"
        >
          <>
            <div className="ShareArticleModal_header">
              <div className="ShareArticleModal_header_title">Notes</div>
            </div>
            <div className="ShareArticleModal_body">
              <div className="ShareArticleModal_body_message">
                <div className="AddUser_data_input_section">
                  <div className="AddUser_data">
                    <TextareaAutosize
                      id="message"
                      type="text"
                      autoComplete="family-name"
                      size="small"
                      variant="outlined"
                      color="primary"
                      rowsMin={5}
                      value={localNotes}
                      onChange={this.updateNotes}
                      variant="filled"
                    />
                  </div>
                </div>
              </div>
              <div className="AddUser_data_actions">
                <Button
                  //onClick={onClose}
                  onClick={this.handleNotesClose}
                  className="AddUser_data_actions_cancel"
                  variant="secondary-inverse"
                  customVariant="AddUser_button_cancel"
                  customContent="Cancel"
                />
                <Button
                  onClick={this.onSubmit}
                  className="AddUser_data_actions_send"
                  variant="secondary-inverse"
                  customVariant="AddUser_button_send"
                  customContent="Save"
                />
              </div>
            </div>

          </>
        </Modal >
      </>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    user: state.user,
    favouriteLists: state.favouriteLists.currentList
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addNotes: addNotes
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);

NotesModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  onClose: Proptypes.bool.isRequired,
  favouriteId: Proptypes.string.isRequired
};
