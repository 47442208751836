import React from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../Button/Button';
import './BlogModal.scss';

// This helps make the modal accessible.
Modal.setAppElement(document.getElementById('sofie-embed'));

/**
 * A Modal that displays the blog content when a blog post is clicked on.
 * @param {object} props - defined in proptypes
 * @returns {*} - blog modal
 * @class
 */
const BlogModal = (props) => {
  const {
    isOpen, content, onCancel, title, imageSrc, imageAlt, date
  } = props;
  /* eslint-disable react/no-danger */
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Blog Modal"
      className="BlogModal"
      overlayClassName="BlogModal_overlay"
      bodyOpenClassName="BlogModal_parent"
    >
      <div className="BlogModal_header">
        <div className="BlogModal_title">
          {date}
        </div>
        <Button
          onClick={onCancel}
          customContent={<span aria-hidden="true">&times;</span>}
          variant="primary-darken"
          customVariant="BlogModal_close"
          type="button"
          width="6rem"
          ariaLabel="Close"
          squareButton
        />
      </div>
      <div className="BlogModal_image_holder">
        <img src={imageSrc} alt={imageAlt} className="img-fluid" />
      </div>
      <div className="BlogModal_body">
        <div className="BlogModal_body_title">
          {title}
        </div>
        <div
          className="BlogModal_body_content"
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </div>
      <div className="BlogModal_footer">
        <Button
          onClick={onCancel}
          customContent="Close"
          variant="primary-darken"
          type="button"
          height="2.5rem"
          width="6rem"
        />
      </div>
    </Modal>
  );
};

export default BlogModal;

BlogModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  date: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  imageSrc: Proptypes.string.isRequired,
  imageAlt: Proptypes.string.isRequired,
  content: Proptypes.string.isRequired,
  onCancel: Proptypes.func.isRequired,
};
