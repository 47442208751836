import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './Favourite.scss';
import FavouriteItem from './FavouriteItem/FavouriteItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFavouriteList, removeFavourite } from '../../actions/addFavourite/addFavourite';
import Pagination from '../Publisher/Pagination/Pagination';
import Button from '../Button/Button';
import RemoveFavouriteModal from '../Modals/RemoveFavouriteModal/RemoveFavouriteModal';

/**
 * The favourite page
 */
class Favourite extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      articleTitle: '',
      currentPage: 0,
      items: 5,
      initialLen: 0,
      modalOpen: false,
      docId: '',
      currentTitle: ''
    };
    this.showArticle = this.showArticle.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.handleItem = this.handleItem.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    // Call the redux action to get all favourites from the store
    const { getFavouriteList } = this.props;
    getFavouriteList();
  }

  /**
   * Gets the article data from the API on component load
   */
  componentDidMount() {
    const { favouriteLists } = this.props;
    const { items } = this.state;

    if (favouriteLists.message) {
      this.setState({
        initialLen: Math.ceil(favouriteLists.message.answers.length / items)
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { currentPage, items } = this.state;
    if (nextProps.favouriteLists.message) {
      let favLength = nextProps.favouriteLists.message.answers.length;

      if (favLength % items === 0 && currentPage !== 0) {
        this.setState({
          currentPage: currentPage - 1
        });
      }
      return true;
    }
  }

  /**
   * Toggles if the modal is open or closed.
   */
  toggleModal(docId) {
    const { modalOpen } = this.state;
    const { favouriteLists } = this.props;

    let data = favouriteLists.message.answers;
    let title = "";
    for (var i = 0; i < data.length; i++) {
      if (docId === data[i].doc_id) {
        title = data[i].title;
      }
    }
    this.setState({
      modalOpen: !modalOpen,
      docId: docId,
      currentTitle: title
    });
  }

  /**
   * remove favourite article
   * @param {object} e - event
   */
  handleRemove() {
    const { removeFavourite } = this.props;
    const { modalOpen, docId } = this.state;
    removeFavourite(docId);

    this.setState({
      modalOpen: !modalOpen
    });
  }

  /**
   * Jumps to the page entered by the user.
   * @param {number} page - page number
   */
  goToPage(page) {
    this.setState({
      currentPage: page,
    });
  }
  /**
   * Shows the selected article. Navigates to a new URL that displays the article.
   * @param {number} id - selected article ID
   */
  showArticle(id) {
    const { history } = this.props;
    const urlId = encodeURIComponent(id);
    history.push(`/favourite-articles/${urlId}`);
  }


  /**
   * Changes the page to the next page.
   */
  nextPage() {
    const { currentPage, initialLen } = this.state;

    if (currentPage === initialLen - 1) {
      this.setState({
        currentPage: 0,
      });
    } else {
      this.setState({
        currentPage: currentPage + 1,
      });
    }
  }

  /**
   * Changes the page to the previously page.
   */
  prevPage() {
    const { currentPage, initialLen } = this.state;
    if (currentPage === 0) {
      this.setState({
        currentPage: initialLen - 1,
      });
    } else {
      this.setState({
        currentPage: currentPage - 1,
      });
    }
  }

  handleItem(val) {
    const { favouriteLists } = this.props;
    const { currentPage, initialLen, items } = this.state;
    let favNum = favouriteLists.message.answers.length;
    let newLen = '';
    newLen = Math.ceil(favNum / val);

    if (currentPage === initialLen - 1 && currentPage !== 0 || favNum < val) {
      this.setState({
        initialLen: newLen,
        items: val,
        currentPage: Math.ceil(favNum / val) - 1
      });
    }
    this.setState({
      initialLen: newLen,
      items: val,
    });

  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { favouriteLists, history, loading } = this.props;
    const { currentPage, items, modalOpen, currentTitle } = this.state;
    let resultsRender = '';
    let button = '';
    let paginationButton = '';
    let pageLen = 0;
    let title = "Are you sure you want to remove:";
    let footer = "from your favorites?";
    let totalLen = 0;
    if (favouriteLists && favouriteLists.message && favouriteLists.message.answers) {
      totalLen = favouriteLists.message.answers.length;
    }

    if (loading) {
      resultsRender = (
        <div className="pt-4 d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      );
    }
    else if (favouriteLists.message.answers.length === 0) {
      resultsRender = (
        <div className="pt-4 d-flex justify-content-center">
          No Favorites, Add it!
        </div>
      );
    }
    else {
      totalLen = favouriteLists.message.answers.length;
      pageLen = Math.ceil(totalLen / items);
      let itemLists = [];
      let data = favouriteLists.message.answers;
      if (currentPage === 0) {
        itemLists = data.slice(0, items);
      } else if (currentPage === 1) {
        itemLists = data.slice(items, items * (currentPage + 1));
      } else {
        itemLists = data.slice(items * currentPage, items * (currentPage + 1));
      }

      resultsRender = itemLists.map((answer) => (
        <div key={answer.doc_id}>
          <FavouriteItem
            data={answer}
            showArticle={this.showArticle}
            key={answer.favourite_id}
            history={history}
            toggleModal={this.toggleModal}
          />
          <RemoveFavouriteModal
            isOpen={modalOpen}
            key={answer.doc_id}
            onCancel={this.toggleModal}
            onConfirm={this.handleRemove}
            title={title}
            message={currentTitle}
            footer={footer}
          />
        </div>
      ));
      button = <Pagination
        length={pageLen}
        currentPage={currentPage}
        prevPage={this.prevPage}
        nextPage={this.nextPage}
        goToPage={this.goToPage}
      />

      paginationButton = totalLen !== 0 ?
        <>
          <Button
            value="5"
            onClick={() => this.handleItem(5)}
            customContent="5"
            type="button"
            variant="custom"
            customVariant={items === 5 ? 'dropdown_item' : ''}
          />
          |
          <Button
            value="10"
            onClick={() => this.handleItem(10)}
            customContent="10"
            type="button"
            variant="custom"
            customVariant={items === 10 ? 'dropdown_item' : ''}
          />
          |
          <Button
            onClick={() => this.handleItem(15)}
            value="15"
            customContent="15"
            type="button"
            variant="custom"
            customVariant={items === 15 ? 'dropdown_item' : ''}
          />
          <div className="dropdown_page">per page</div>
        </> : '';

    }
    ;
    return (
      <div className="favourite">
        <div className="favourite_header">
          <h2 className="favourite_title">My Favorites</h2>
          <div className="favourite_dropdown">
            {paginationButton}
          </div>
        </div>
        <div className="favourite_list">
          {resultsRender}
          {button}
        </div>
      </div>
    );
  }
}

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    // the below items are available in props and not states
    favouriteLists: state.favouriteLists.currentList,
    loading: state.favouriteLists.loading,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFavouriteList: getFavouriteList,
    removeFavourite: removeFavourite
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourite);

Favourite.propTypes = {
  history: Proptypes.shape({
    push: Proptypes.func,
    location: Proptypes.shape({
      search: Proptypes.string
    })
  }).isRequired
};
