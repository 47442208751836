import React from 'react';
import classNames from 'classnames';
import * as Proptypes from 'prop-types';
import Checkbox from '../../../Checkbox/Checkbox';
import './AskAnswer.scss';
import Button from '../../../Button/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addtoFavourite, removeFavourite } from '../../../../actions/addFavourite/addFavourite';

/**
 * Renders an answer to a users query.
 * @param {object} props - defined in proptypes
 * @returns {*} - Answer
 * @class
 */
const AskAnswer = (props) => {
  const {
    data, addChecked, removeChecked, showArticle, selected, favouriteLists
  } = props;

  let isFavourite = false;

  if (favouriteLists && favouriteLists.message && favouriteLists.message.answers) {
    const favouriteAnswers = favouriteLists.message.answers;
    isFavourite = favouriteAnswers.findIndex((favourite) => favourite.doc_id === data.id) != -1;
  }

  /**
   * Handles checking the checkbox.
   */
  function check() {
    addChecked(data.id);
  }

  /**
   * Handles unchecking the checkbox.
   */
  function uncheck() {
    removeChecked(data.id);
  }

  /**
   * Fires the show article function.
   */
  function onClick() {
    showArticle(data.id);
  }

  /**
   * @returns {*} -
   * @param event
   * @param documentId - documentId
   */
  function addToFavourite(event, documentId) {
    event.stopPropagation();
    const { addFavouriteArticle } = props;
    addFavouriteArticle(documentId);
  }

  /**
   * @returns {*} -
   * @param event
   * @param documentId - documentId
   */
  function removeFavouriteClick(event, documentId) {
    event.stopPropagation();
    const { removeFavouriteArticle } = props;
    removeFavouriteArticle(documentId);
  }

  /**
   * checks to see if the enter key has been pressed.
   * @param {object} e - event
   */
  function keyActivation(e) {
    if (e.key === 'Enter') {
      showArticle(data.id);
    }
  }

  let details = '';
  const publisher = data.bookInfo ? (
    <a rel="noopener noreferrer" target="_blank" href={data.bookInfo[0].website}>
      {data.bookInfo[0].publisher_name}
    </a>
  ) : '';

  if (data.bookInfo) {
    const book = data.bookInfo && data.bookInfo[0].book ? `${data.bookInfo[0].book} - ` : '';
    const year = data.bookInfo && data.bookInfo[0].year ? `${data.bookInfo[0].year}:` : '';

    if (data.bookInfo[0].edition) {
      const author = data.bookInfo && data.bookInfo[0].authors
        ? `${data.bookInfo[0].authors.join(', ')}:` : '';
      const edition = data.bookInfo ? `${data.bookInfo[0].edition}:` : '';
      details = `${author} ${book} ${edition} `;
    } else {
      details = `${book} ${year} `;
    }
  }

  return (
    <div className="ask-answer">
      <div className="ask-answer_leftCol">
        <Checkbox
          checked={selected}
          id={data.id}
          type="secondary"
          onCheck={check}
          onUnCheck={uncheck}
          customIconClass="ask-answer_check"
          boxSize="25px"
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="listitem"
        className="ask-answer_interactive"
        onClick={onClick}
        onKeyDown={keyActivation}
      >
        <div className="ask-answer_interactive_leftCol">
          <p className="ask-answer_title">
            {data.title}
            {isFavourite ? (
              <Button
                onClick={(event) => removeFavouriteClick(event, data.id)}
                customContent={<i className="fas fa-heart" />}
                variant="custom"
                customVariant="ask-answer_favourite_button"
                type="button"
              />
            ) : (
              <Button
                onClick={(event) => addToFavourite(event, data.id)}
                customContent={<i className="far fa-heart" />}
                variant="custom"
                customVariant=""
                type="button"
              />
            )}
          </p>

          <p className="ask-answer_author">
            {details}
            {publisher}
          </p>
          <p className="ask-answer_text">{`${data.text}...`}</p>
        </div>
        <div className="ask-answer_interactive_rightCol">
          <i className={classNames('fas', 'fa-chevron-right', 'ask-answer_arrow')} />

        </div>
      </div>
    </div>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    // the below items are available in props and not states
    user: state.user,
    favouriteLists: state.favouriteLists.currentList,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{ setSelectedArticles: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addFavouriteArticle: addtoFavourite,
    removeFavouriteArticle: removeFavourite,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AskAnswer);

AskAnswer.propTypes = {
  showArticle: Proptypes.func.isRequired,
  removeChecked: Proptypes.func.isRequired,
  addChecked: Proptypes.func.isRequired,
  data: Proptypes.shape({
    id: Proptypes.string.isRequired,
    title: Proptypes.string.isRequired,
    text: Proptypes.string.isRequired,
    bookInfo: Proptypes.arrayOf(Proptypes.shape({
      authors: Proptypes.arrayOf(Proptypes.string),
      book: Proptypes.string,
      edition: Proptypes.string,
      publisher_name: Proptypes.string,
      website: Proptypes.string,
      year: Proptypes.oneOfType([Proptypes.string, Proptypes.number])
    }))
  }).isRequired,
  selected: Proptypes.bool.isRequired
};
