exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".textbook{display:flex;flex-flow:column nowrap;flex:0 1 30%;margin:0 1rem 3rem 1rem;max-width:33.3333%;min-width:14rem;height:14rem}.textbook_title{text-align:center}.textbook_arrow{font-size:60px;position:absolute;top:50%;transform:translateY(-50%);color:#f8992d}.textbook_holder{display:flex;flex-flow:row nowrap;justify-content:center;width:100%;height:100%}.textbook_image{max-width:100%;max-height:120px}.textbook_arrowcontainer{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};