exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".RemoveFavouriteModal{display:flex;flex-flow:column wrap;align-self:flex-start;margin:3rem 0;background:#fff;overflow:auto;border-radius:4px;outline:none;min-width:383.5px;max-width:40%}.RemoveFavouriteModal_header{display:flex;flex-flow:row nowrap;width:60%;margin:2rem 20% 1rem 20%;color:#f8992d;text-align:center}.RemoveFavouriteModal_header_title{font-size:xx-large}.RemoveFavouriteModal_body_title{display:flex;flex-flow:column wrap;margin:0 1rem 1rem 1rem;padding:0 20px 0 20px}.RemoveFavouriteModal_body_title_message{font-size:1.2rem;font-style:italic;text-align:center;width:80%;margin:0 10%}.RemoveFavouriteModal_body_footer{color:#f8992d}.RemoveFavouriteModal_body_footer_message{font-size:1.5rem;width:80%;margin:0 10%;text-align:center}.RemoveFavouriteModal_footer{display:flex;flex-flow:row nowrap;justify-content:space-around;margin:1rem 0;padding-top:1rem}.RemoveFavouriteModal_overlay{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);overflow-y:auto;display:flex;flex-flow:row nowrap;justify-content:center}.RemoveFavouriteModal_parent{overflow:hidden}.RemoveFavouriteModal_cancel{color:#fff;background-color:#f8992d;margin-bottom:2rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};