import React from 'react';
import * as Proptypes from 'prop-types';
import './Textbook.scss';

/**
 * Generates the textbook tile for the textbook section in the publisher page.
 * @param {object} props - defined in proptypes
 * @returns {*} - Textbook tile.
 * @class
 */
const Textbook = (props) => {
  const {
    id, title, logo, edition, authors, publisher, year
  } = props;
  let authorString = '';

  if (authors.length > 1) {
    authors.forEach((author) => {
      authorString += `${author.first_name} ${author.last_name}, `;
    });
    authorString = authorString.substring(0, authorString.length - 2);
  } else {
    authorString = `${authors[0].first_name} ${authors[0].last_name}`;
  }

  return (
    <div className="textbook" key={id}>
      <div className="textbook_holder">
        <img
          src={`${UM_URL}media/book-banner/${logo}`}
          alt={`${title} Logo`}
          className="textbook_image"
        />
      </div>
      <p className="textbook_title">
        {`${title}, ${edition} (${authorString}; ${publisher} ${year})`}
      </p>
    </div>
  );
};

export default Textbook;

Textbook.propTypes = {
  id: Proptypes.number.isRequired,
  title: Proptypes.string.isRequired,
  logo: Proptypes.string.isRequired,
  edition: Proptypes.string.isRequired,
  authors: Proptypes.arrayOf(
    Proptypes.shape({
      first_name: Proptypes.string,
      last_name: Proptypes.string,
    })
  ).isRequired,
  publisher: Proptypes.string.isRequired,
  year: Proptypes.number.isRequired,
};
