exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".Footer{display:flex;flex-flow:column nowrap;flex:1 0 auto;justify-content:flex-end;padding-top:3rem}.Footer_links{text-align:center;margin-bottom:1rem;padding:0 1rem}.Footer_disclaimer{text-align:center;padding:0 1rem}.Footer_link{color:#2E2EFF;border:none;padding:0 1px;font-weight:400;background-color:#fff}.Footer_link:hover{color:#000}.Footer_activeLink{color:#000;border:none;padding:0;background-color:#fff}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};