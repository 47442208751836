import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Button from '../Button/Button';
import '../../assets/svgs/logo.svg';
import '../Header/Header.scss';
/**
 * Header Component which loads the sofie logo, welcome message and nav buttons.
 */
class PublicHeader extends Component {
  redirect() {
    window.location.href = '/';
  }
  /**
   * Renders component
   * @returns {​​​​​​​​*}​​​​​​​​ - DOM description
   */
  render() {
    return (
      <div className="Header">
        <Navbar bg="white" expand="lg">
          <Navbar.Brand>
            <Button
              onClick={() => { this.redirect(); }}
              variant="custom"
              customVariant="Header_image"
              customContent={(
                <Image
                  src={`${UM_URL}svg/logo.svg`}
                  className="logo img-responsive"
                  width="175px"
                />
              )}
              squareButton
            />
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }
}
export default PublicHeader;
