exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".publishersection{display:flex;flex-flow:column nowrap;width:100%;justify-content:center;align-self:center}.publishersection_title{color:#f8992d;text-align:center;font-size:2rem;font-weight:500;padding:1rem 0 2rem 0;border-top:4px solid #ccc;letter-spacing:1px}.publishersection_link{border:none;background-color:transparent}.publishersection_image{max-width:100%;max-height:100%}.publishersection_image_holder{min-height:1px;align-self:center;height:100%;width:100%}.publishersection_publishers{display:flex;flex-flow:row wrap;flex:1;align-self:center;justify-content:center}.publishersection_publishers_publisher{display:flex;flex-flow:column nowrap;justify-content:center;margin:0 1rem 3rem 1rem;flex:0 1 30%;max-width:33.3333%;min-width:175px;height:175px}.publishersection_loader{color:#f8992d;align-self:center;padding-top:2rem;margin-bottom:3rem}.publishersection_loader_title{text-align:center;font-size:xx-large;font-weight:500;margin-top:3rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};