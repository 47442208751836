exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".BlogModal{display:flex;flex-flow:column nowrap;align-self:flex-start;flex:1 0 70%;max-width:80%;margin-top:3rem;background:#fff;border-radius:4px;outline:none;margin-bottom:20%}.BlogModal_image{align-self:center;max-width:100%}@media (min-width: 800px){.BlogModal_image{max-width:800px}}.BlogModal_image_holder{display:flex;flex-flow:row nowrap;flex:0 1 auto;justify-content:center}.BlogModal_body{padding:1.5rem 12% 0;border-bottom:1px solid #ececec;word-break:break-word;overflow-wrap:break-word;word-wrap:break-word;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}.BlogModal_body_title{color:#006281;font-size:x-large;font-weight:500;padding-bottom:1.5rem}.BlogModal_body_content img{max-width:100%}.BlogModal_footer{display:flex;flex-flow:row;justify-content:flex-end;margin:1rem}.BlogModal_header{display:flex;flex-flow:row nowrap;color:#fff;background-color:#006281;justify-content:space-between;border:none}.BlogModal_close{font-size:2rem;align-self:center}.BlogModal_title{font-size:larger;font-weight:bold;align-self:center;padding-left:1rem}.BlogModal_overlay{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);overflow-y:scroll;display:flex;flex-flow:row nowrap;justify-content:center}.BlogModal_parent{overflow:hidden}.BlogModal a{color:#2E2EFF}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};