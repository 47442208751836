import React, { Component } from 'react';
import classNames from 'classnames';
import * as Proptypes from 'prop-types';
import './FavouriteItem.scss';
import Button from '../../Button/Button';

/**
 * Renders a favourite list to a users query.
 * @param {object} props - defined in proptypes
 * @returns {*} - favourite item
 * @class
 */

class FavouriteItem extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.keyActivation = this.keyActivation.bind(this);
  }


  /**
    * Fires the show article function.
    */
  onClick() {
    const { data } = this.props;
    const { showArticle } = this.props;
    showArticle(data.doc_id);
  }

  /**
     * checks to see if the enter key has been pressed.
     * @param {object} e - event
     */
  keyActivation(e) {
    const { showArticle, data } = this.props;
    if (e.key === 'Enter') {
      showArticle(data.doc_id);
    }
  }

  render() {
    const { data, toggleModal } = this.props;

    let details = '';
    const publisher = data.bookInfo ? (
      <a rel="noopener noreferrer" target="_blank" href={data.bookInfo[0].website}>
        {data.bookInfo[0].publisher_name}
      </a>
    ) : '';

    if (data.bookInfo) {
      const book = data.bookInfo && data.bookInfo[0].book ? `${data.bookInfo[0].book} - ` : '';
      const year = data.bookInfo && data.bookInfo[0].year ? `${data.bookInfo[0].year}:` : '';

      if (data.bookInfo[0].edition) {
        const author = data.bookInfo && data.bookInfo[0].authors
          ? `${data.bookInfo[0].authors.join(', ')}:` : '';
        const edition = data.bookInfo ? `${data.bookInfo[0].edition}:` : '';
        details = `${author} ${book} ${edition} `;
      } else {
        details = `${book} ${year} `;
      }
    }

    return (
      <div className="favouite-item">
        <div className="remove_icon">
          <Button
            onClick={() => { toggleModal(data.doc_id) }}
            customContent={<i className="fas fa-times" />}
            type="button"
            variant="custom"
            customVariant="favourite-item_remove"
          />
        </div>
        <div
          role="listitem"
          className="favourite-item_interactive"
          onClick={this.onClick}
          onKeyDown={this.keyActivation}
        >
          <div className="favourite-item_interactive_leftCol">
            <p className="favourite-item_title">{data.title}</p>
            <p className="favourite-item_author">
              {details}
              {publisher}
            </p>
            <p className="favourite-item_text">{`${data.text}...`}</p>
          </div>
          <div className="favourite-item_rightCol">
            <i className={classNames('fas', 'fa-chevron-right', 'favourite-item_arrow')} />
          </div>
        </div>
      </div>
    );
  }
}

export default FavouriteItem;

FavouriteItem.propTypes = {
  showArticle: Proptypes.func.isRequired,
  toggleModal: Proptypes.func.isRequired,
  data: Proptypes.shape({
    title: Proptypes.string.isRequired,
    text: Proptypes.string.isRequired,
    bookInfo: Proptypes.arrayOf(Proptypes.shape({
      book: Proptypes.string,
      id: Proptypes.number,
      publisher_name: Proptypes.string,
      website: Proptypes.string,
      year: Proptypes.oneOfType([Proptypes.string, Proptypes.number])
    }))
  }).isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired,
};
