exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".textPage{display:flex;flex-flow:column nowrap;flex:1 0 auto;margin:2rem 10%}@media (max-width: 800px){.textPage{margin:2rem 5%}}.textPage_loader{color:#f8992d}.textPage a{color:#2E2EFF}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};