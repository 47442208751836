exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ask{height:auto;flex:1 0 auto}.ask_nothing{font-size:x-large;text-align:center;margin:2rem;color:#006281}.ask_spinner{color:#f8992d}.ask_button{display:flex;flex-flow:row nowrap;justify-content:space-around;border-bottom:0.1rem solid #000;width:14rem}.ask_button_title{cursor:pointer;font-style:italic;margin-bottom:-0.1rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};