import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Button from '../Button/Button';
import './Footer.scss';

/**
 * Footer component that appears on the bottom of all pages that a logged in user can view.
 */
class Footer extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
    };
    this.navTo = this.navTo.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  /**
   * Handles changing the page when a footer link is clicked.
   * @param {string} path - path to navigate to
   */
  navTo(path) {
    const { history } = this.props;
    history.push(path);
  }

  /**
   * Checks if the page associated with the link is currently being displayed.
   * @param {string} path - current url path
   * @returns {boolean} - if page associated with link is being shown
   */
  isActive(path) {
    const { location } = this.props;
    return (location.localeCompare(path) === 0);
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { location } = this.props;
    const { currentYear } = this.state;

    return (
      <div className="Footer">
        <div className="Footer_links">
          {' LifeLearn '}
          &copy;
          {` ${currentYear} `}
          <Button
            onClick={() => window.open('https://www.lifelearn.com/sofie-terms-and-conditions', '_blank')}
            variant="custom"
            customContent="Terms and Conditions"
            customVariant="Footer_link"
            squareButton
          />
          {' | '}
          <Button
            onClick={() => this.navTo('/disclaimer')}
            variant="custom"
            customVariant={this.isActive('/disclaimer') ? 'Footer_activeLink' : 'Footer_link'}
            customContent="LifeLearn Disclaimer"
            squareButton
            disabled={location.localeCompare('/disclaimer') === 0}
          />
          {' | '}
          <Button
            onClick={() => window.open('https://www.lifelearn.com/privacy-policy/', '_blank')}
            variant="custom"
            customVariant="Footer_link"
            customContent="LifeLearn Privacy Policy"
            squareButton
          />
          {' | '}
          <Button
            onClick={() => this.navTo('/publisher')}
            variant="custom"
            customVariant={this.isActive('/publisher') ? 'Footer_activeLink' : 'Footer_link'}
            customContent="Publications"
            squareButton
            disabled={location.localeCompare('/publisher') === 0}
          />
          {' | '}
          <Button
            onClick={() => window.open('https://www.lifelearn.com/sofie-resources/', '_blank')}
            variant="custom"
            customVariant="Footer_link"
            customContent="Help"
            squareButton
          />
        </div>
        <div className="Footer_disclaimer">
          <p>
            Built with Watson - IBM Watson is a trademark of International
            Business Machines Corporation, registered in many jurisdictions worldwide.
          </p>
          {/* Used to get the version number in the application */}
          <p>
            {`v${SOFIE_VERSION}`}
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;

Footer.propTypes = {
  embed: Proptypes.bool.isRequired,
  history: Proptypes.shape({
    push: Proptypes.func
  }).isRequired,
  location: Proptypes.string.isRequired
};
