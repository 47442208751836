const ThemeConfig = {
  palette: {
    primary: {
      main: '#006281',
    },
    secondary: {
      main: '#f8992d',
    },
    tertiary: {
      main: '#ea8008'
    },
    yellow: {
      main: '#f7b330'
    }
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  }
};

export default ThemeConfig;
