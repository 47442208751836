exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ShareArticleModal{background-color:#ececec}.ShareArticleModal_header_title{font-size:1.2rem}.AddUser_data_input_section{justify-content:center;width:100%}textarea{border:0.1rem solid transparent;border-bottom:0.15rem solid #f8992d;height:15rem !important;overflow:inherit !important}.AddUser_button_send{background-color:#f8992d;color:#fff;font-size:1.1rem;padding:0.2rem 2rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};