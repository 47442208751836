import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ArticleBody = (props) => {
  const { data } = props;
  // useEffect(() => {
  //   const elmnt = document.getElementsByTagName('mark');
  //   if (elmnt[0]) {
  //     elmnt[0].scrollIntoView();
  //   }
  // }, []);
  return (
    <div>{data}</div>
  );
};
export default ArticleBody;
ArticleBody.propTypes = {
  data: PropTypes.object.isRequired
};
