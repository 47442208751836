import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import Textbook from './Textbook/Textbook';
import Pagination from '../Pagination/Pagination';
import './TextbookSection.scss';

/**
 * Section of the publisher page that shows all the textbooks use in Sofie.
 */
class TextbookSection extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  /**
   * Shows the next page of textbooks.
   */
  nextPage() {
    const { currentPage } = this.state;
    const { books } = this.props;
    if (currentPage === books.length - 1) {
      this.setState({
        currentPage: 0
      });
    } else {
      this.setState({
        currentPage: currentPage + 1
      });
    }
  }

  /**
   * Shows the previous page of textbooks.
   */
  prevPage() {
    const { currentPage } = this.state;
    const { books } = this.props;
    if (currentPage === 0) {
      this.setState({
        currentPage: books.length - 1
      });
    } else {
      this.setState({
        currentPage: currentPage - 1
      });
    }
  }

  /**
   * Jumps to the page entered by the user.
   * @param {number} page - page number
   */
  goToPage(page) {
    this.setState({
      currentPage: page
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { books, loading } = this.props;
    const { currentPage } = this.state;

    let content = (
      <div className="textbooksection_books">
        No textbooks found.
      </div>
    );
    if (loading) {
      content = (
        <div className="textbooksection_loader">
          <div className="textbooksection_loader_title">
            Fetching Textbooks
          </div>
          <div className="textbooksection_loader_spinner">
            <Spinner animation="border" />
          </div>
        </div>
      );
    }

    let currentBooks = '';
    if (books.length > 0) {
      currentBooks = books[currentPage].map((book) => (
        <Textbook
          key={book.id}
          authors={book.authors}
          edition={book.name}
          id={book.id}
          logo={book.image}
          publisher={book.book.publisher.name}
          title={book.book.name}
          year={book.year}
        />
      ));
    }

    if (books.length > 0) {
      content = (
        <div className="textbooksection_books">
          {currentBooks}
        </div>
      );
    }

    return (
      <div className="textbooksection">
        <div className="textbooksection_title">
          Textbooks
        </div>
        <div className="d-flex justify-content-center">
          {content}
        </div>
        <Pagination
          currentPage={currentPage}
          length={books.length}
          prevPage={this.prevPage}
          nextPage={this.nextPage}
          goToPage={this.goToPage}
        />
      </div>
    );
  }
}

export default TextbookSection;

TextbookSection.propTypes = {
  loading: Proptypes.bool.isRequired,
  books: Proptypes.arrayOf(
    Proptypes.arrayOf(
      Proptypes.shape({
        id: Proptypes.number,
        title: Proptypes.string,
        banner: Proptypes.string,
        edition: Proptypes.string,
        author: Proptypes.shape({
          name: Proptypes.arrayOf(
            Proptypes.string
          )
        }),
        publisher_name: Proptypes.string,
        year: Proptypes.number,
      })
    ),
  )
};

TextbookSection.defaultProps = {
  books: '',
};
