exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".blogPost{display:flex;flex-flow:column nowrap;flex:0 1 30%;border:1px solid #000;margin:0 1rem 3rem 1rem;min-width:14rem;max-width:33.3333%}.blogPost_header{background-color:#006281;color:#fff;padding:1rem 0 0 1rem;border-bottom:#488fb1}.blogPost_content{padding:15px;word-break:keep-all;overflow-wrap:break-spaces;word-wrap:break-spaces;-ms-hyphens:auto;-moz-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}.blogPost_content h3{font-size:20px;color:#006281}.blogPost_content p{margin:0}.blogPost_viewarticle{background-color:#fff;border:none;text-decoration:underline;color:#006281;margin:10px 0;padding:0;font-size:18px}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};