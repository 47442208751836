import React from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../Button/Button';
import './AlertModal.scss';

// This helps make the modal accessable.
Modal.setAppElement(document.getElementById('sofie-embed'));

/**
 * A modal that alerts the user to some imporant piece of information.
 * @param {object} props - defined in proptypes
 * @returns {*} - modal pop up
 * @class
 */
const AlertModal = (props) => {
  const {
    isOpen, onClose, title, children
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirmation Modal"
      className="AlertModal"
      overlayClassName="AlertModal_overlay"
      bodyOpenClassName="AlertModal_parent"
    >
      <>
        <div className="AlertModal_header">
          <div className="AlertModal_header_title">{title}</div>
        </div>
        <div className="AlertModal_body">
          <div className="AlertModal_body_message">
            {children}
          </div>
        </div>
        <div className="AlertModal_footer">
          <Button
            onClick={onClose}
            customContent="Close"
            variant="warning-darken"
            height="3rem"
            width="10rem"
          />
        </div>
      </>
    </Modal>
  );
};

export default AlertModal;

AlertModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  onClose: Proptypes.func.isRequired,
  children: Proptypes.element.isRequired,
};
