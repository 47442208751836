import React from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import Button from '../../Button/Button';
import './RemoveFavouriteModal.scss';

// This helps make the modal accessable.
Modal.setAppElement(document.getElementById('sofie-embed'));

/**
 * A modal asking the user to confirm an action.
 * If the user confirms the action should be executed.
 * On any other action the modal should be closed and the action cancelled.
 * @param {object} props - defined in proptypes
 * @returns {*} - modal pop up
 * @class
 */
const RemoveFavouriteModal = (props) => {
  const {
    isOpen, message, onCancel, onConfirm, title, footer
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="RemoveFavouriteModal Modal"
      className="RemoveFavouriteModal"
      overlayClassName="RemoveFavouriteModal_overlay"
      bodyOpenClassName="RemoveFavouriteModal_parent"
    >
      <>
        <div className="RemoveFavouriteModal_header">
          <div className="RemoveFavouriteModal_header_title">{title}</div>
        </div>
        <div className="RemoveFavouriteModal_body_title">
          <div className="RemoveFavouriteModal_body_title_message">{message}</div>
        </div>
        <div className="RemoveFavouriteModal_body_footer">
            <div className="RemoveFavouriteModal_body_footer_message">{footer}</div>
        </div>
        <div className="RemoveFavouriteModal_footer">
          <Button
            onClick={onCancel}
            customContent="Cancel"
            variant="custom"
            customVariant="RemoveFavouriteModal_cancel"
            height="3rem"
            width="10rem"
          />
          <Button
            onClick={onConfirm}
            customContent="Remove"
            variant="errorDark-darken"
            height="3rem"
            width="10rem"
          />
        </div>
      </>
    </Modal>
  );
};

export default RemoveFavouriteModal;

RemoveFavouriteModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  message: Proptypes.string.isRequired,
  onCancel: Proptypes.func.isRequired,
  onConfirm: Proptypes.func.isRequired
};
