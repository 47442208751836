import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {
  ACCEPT_AGREEMENT_REQUEST, ACCEPT_AGREEMENT_SUCCESS, UPDATE_USER_DETAILS,
  COMPLETED_SURVEY_REQUEST, COMPLETED_SURVEY_SUCCESS, SEEN_EXPIRY_WARNING
} from '../actions';
import AuthRequest from '../../api/AuthRequest';

/**
 * Action creator for sending accept agreement request.
 * @returns {object} - action
 */
function acceptAgreementRequest() {
  return {
    type: ACCEPT_AGREEMENT_REQUEST,
  };
}

/**
 * Action creator for user accepting the terms and conditions.
 * @returns {object} - action
 */
function acceptAgreementSuccess() {
  return {
    type: ACCEPT_AGREEMENT_SUCCESS,
  };
}

/**
 * Action creator for sending completed survey request.
 * @returns {object} - action
 */
function completedSurveyRequest() {
  return {
    type: COMPLETED_SURVEY_REQUEST,
  };
}

/**
 * Action creator for user completing the survey.
 * @returns {object} - action
 */
function completedSurveySuccess() {
  return {
    type: COMPLETED_SURVEY_SUCCESS,
  };
}

/**
 * Action creator for user closing/seeing account license expiry warning modal.
 * @returns {object} - action
 */
export function sawExpiryWarning() {
  return {
    type: SEEN_EXPIRY_WARNING
  };
}

/**
 * Updates the logged in users details when their information changes.
 * @param {object} details - an object that contains the updated user details.
 * @returns {object} - action
 */
export function updateUserDetails(details) {
  return {
    type: UPDATE_USER_DETAILS,
    payload: details
  };
}

/**
 * Saves user accepted the terms and conditions.
 * @returns {Function} - updates redux and backend
 */
export function acceptAgreement() {
  return (dispatch) => {
    dispatch(acceptAgreementRequest());
    AuthRequest({
      method: 'post',
      url: 'accept-agreement',
    }).then(() => {
      ReactGA.event({
        category: 'User',
        action: 'accept-terms'
      });
      ReactGA4.event('accept-terms');
      dispatch(acceptAgreementSuccess());
    });
  };
}

/**
 * Saves user has completed the survey.
 * @returns {Function} - updates redux and backend
 */
export function completedSurvey() {
  return (dispatch) => {
    dispatch(completedSurveyRequest());
    AuthRequest({
      method: 'post',
      url: 'accept-surveygismo',
    }).then(() => {
      dispatch(completedSurveySuccess());
    });
  };
}
