exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".textbooksection{display:flex;flex-flow:column nowrap;width:100%;justify-content:center;align-self:center;margin-bottom:3rem}.textbooksection_title{color:#f8992d;text-align:center;font-size:2rem;font-weight:500;padding:1rem 0 2rem 0;border-top:4px solid #ccc;letter-spacing:1px}.textbooksection_books{display:flex;flex-flow:row wrap;width:100%;flex:1 1 100%;justify-content:center;align-self:center}.textbooksection_arrow{font-size:60px;position:absolute;top:50%;transform:translateY(-50%);color:#f8992d}.textbooksection_arrowcontainer{cursor:pointer;background-color:#fff;border:none}.textbooksection_loader{display:flex;flex-flow:column nowrap;flex:1 0 auto;align-self:center;justify-content:center;padding-top:2rem;margin-bottom:3rem}.textbooksection_loader_title{text-align:center;font-size:xx-large;font-weight:500;margin-top:3rem}.textbooksection_loader_spinner{color:#f8992d;align-self:center;padding-top:2rem;margin-bottom:3rem}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};