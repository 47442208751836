import axios from 'axios';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import Request from '../../api/Request';
import AuthRequest from '../../api/AuthRequest';
import {
  LOGOUT_REQUEST,
  LOGOUT_COMPLETE,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  STORE_UUID,
  LOGOUT_INVALID_TOKEN,
} from '../actions';

// ===== ACTION CREATORS =====
/**
 * Action creator for logout request.
 * @returns {object} - action
 */
function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

/**
 * Action creator for "logging" the user out of redux.
 * @returns {object} - action
 */
function logoutComplete() {
  return {
    type: LOGOUT_COMPLETE,
  };
}

/**
 * Action creator for "logging" the user out of redux.
 * @returns {object} - action
 */
function logoutCompleteInvalid() {
  return {
    type: LOGOUT_INVALID_TOKEN,
  };
}

/**
 * Action creator for storing UUID.
 * @returns {object} - action
 * @param {string} uuid - unique user id
 */
export function storeUUID(uuid) {
  return {
    type: STORE_UUID,
    payload: uuid,
  };
}

/**
 * Action creator for login requests.
 * @returns {object} - action
 */
function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

/**
 * Action creator for failed login.
 * @returns {object} - action
 * @param {object} error - contains information about the error.
 */
function loginFailure(error) {
  let errorMessage = 'An error with an invalid structure was returned unable to parse.';
  if (error && error.data && (error.data.message || error.data.error)) {
    // eslint-disable-next-line no-console
    console.log(`${error.status} ${error.statusText}: ${error.data.message} ${error.data.error}`);
    errorMessage = error.data.message ? `${error.data.message}` : `${error.data.error}`;
  }

  return {
    type: LOGIN_FAILURE,
    payload: {
      errorMsg: errorMessage,
    },
  };
}

/**
 * Action creator for updating the users information in the redux store for a successful login.
 * @param {object} user - response data
 * @returns {object} - returns the action
 */
export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
}

// ===== MIDDLEWARE =====
/**
 * Check if the user is already undergoing an authentication process.
 * @param {object} state - current redux store state
 * @returns {boolean} - x
 */
export function shouldAuthenticate(state) {
  const now = Math.floor(new Date().getTime() / 1000);
  const isTokenValid = !!state.user.token && state.user.tokenExpiry - now >= MODAL_TIMER;
  const isLoginInProgress = !!state.auth.loginInProgress && state.auth.loginInProgressExpiry > now;

  return !isTokenValid && !isLoginInProgress;
}

/**
 * Handles executing logout requests.
 * @returns {Function} - updates redux store
 * @param {boolean} invalid - whether this is a logout for invalid token
 */
export function attemptLogout(invalid = false) {
  return (dispatch) => new Promise((resolve) => {
    dispatch(logoutRequest());
    AuthRequest({
      method: 'post',
      url: 'logout',
    })
      .then(() => {
        if (invalid) {
          dispatch(logoutCompleteInvalid());
        } else {
          dispatch(logoutComplete());
        }
        AuthRequest.defaults.headers.common.Authorization = '';
        return resolve();
      })
      .catch(() => {
        if (invalid) {
          dispatch(logoutCompleteInvalid());
        } else {
          dispatch(logoutComplete());
        }
        AuthRequest.defaults.headers.common.Authorization = '';
        return resolve();
      });
  });
}

/**
 * Handles executing login requests
 * @returns {Function} - executes redux updates.
 * @param {string} token - Old token to be expired
 * @param {string} UUID - UUID to be passed to UM for login
 * @param {object} cancelToken - token for cancelling requests.
 */
export function attemptLogin(token, UUID, cancelToken = '') {
  return (dispatch, getState) => {
    if (shouldAuthenticate(getState())) {
      dispatch(loginRequest());
      Request({
        method: 'post',
        url: 'login',
        data: {
          id: UUID,
          token,
        },
        cancelToken,
      })
        .then((res) => {
          AuthRequest.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
          if (APP_ENV.localeCompare('production') === 0) {
            ReactGA.set({
              dimension17: `${res.data.user_details.user_id}`,
              dimension18:
                res.data.user_details.account_id === null
                  ? '0'
                  : `${res.data.user_details.account_id}`,
              dimension19: `${res.data.user_details.company_id}`,
            });
          } else {
            ReactGA.set({
              dimension1: `${res.data.user_details.user_id}`,
              dimension2:
                res.data.user_details.account_id === null
                  ? '0'
                  : `${res.data.user_details.account_id}`,
              dimension3: `${res.data.user_details.company_id}`,
            });
          }
          ReactGA4.set({
            userId: `${res.data.user_details.user_id}`,
          });
          ReactGA4.gtag('set', 'user_properties', {
            user_id_custom: `${res.data.user_details.user_id}`,
          });
          dispatch(loginSuccess(res.data));
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch(
              loginFailure({
                data: {
                  message: 'Cancelled fetching user details',
                },
              })
            );
          } else if (
            error.message
            && !error.response
            && !error.response.data
            && !error.response.data.message
          ) {
            dispatch(
              loginFailure({
                data: {
                  message: error.message,
                },
              })
            );
          } else if (error.response.status === 406) {
            dispatch(loginFailure(error.response));
            window.location.href = LOGOUT_URL;
          } else {
            dispatch(loginFailure(error.response));
          }
        });
    }
  };
}

/**
 * Handles the login process for users in an embedded environment.
 * @param {string} thirdPartyToken - token sent by third party for validation
 * @param {number} userID - the sofie ID of the user attempting to log in
 * @param {string} token - the old token to be expired
 * @param {number} cancelToken - token used for cancelling axios request
 * @returns {Function} - executable
 */
export function thirdPartyLogin(thirdPartyToken, userID, token = '', cancelToken = '') {
  return (dispatch, getState) => {
    if (shouldAuthenticate(getState())) {
      dispatch(loginRequest());
      Request({
        method: 'post',
        url: '/ignite/login',
        data: {
          user_id: userID,
          token: thirdPartyToken,
          old_token: token,
        },
        cancelToken,
      })
        .then((res) => {
          AuthRequest.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
          if (APP_ENV.localeCompare('production') !== 0) {
            ReactGA.set({
              dimension1: `${res.data.user_details.user_id}`,
              dimension2: `${res.data.user_details.account_id}`,
              dimension3: `${res.data.user_details.company_id}`,
            });
          } else {
            ReactGA.set({
              dimension17: `${res.data.user_details.user_id}`,
              dimension18: `${res.data.user_details.account_id}`,
              dimension19: `${res.data.user_details.company_id}`,
            });
          }
          ReactGA4.set({
            userId: `${res.data.user_details.user_id}`,
          });
          ReactGA4.gtag('set', 'user_properties', {
            user_id_custom: `${res.data.user_details.user_id}`,
          });
          dispatch(loginSuccess(res.data));
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            dispatch(
              loginFailure({
                data: {
                  message: 'Login request was cancelled.',
                },
              })
            );
          } else {
            dispatch(loginFailure(error.response));
          }
        });
    }
  };
}
