import React, { Component } from 'react';
import AuthRequest from '../../api/AuthRequest';
import TextbookSection from './TextbookSection/TextbookSection';
import Journals from './Journals/Journals';
import PublisherSection from './PublisherSection/PublisherSection';
import BlogSection from './BlogSection/BlogSection';
import './Publisher.scss';

/**
 * Generates the publisher page.
 * Lists out the textbooks, blogs and publishing partners that make up Softies content.
 * @returns {*} - Publisher page.
 * @class
 */
class Publisher extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      textBooks: [],
      conferencesJournals: [],
      loading: true
    };
  }

  /**
   * Retrieves all the books and conference journals used in Sofie.
   */
  componentDidMount() {
    AuthRequest({
      method: 'get',
      url: 'all-books',
    }).then((res) => {
      const booksSeparated = [];
      const conferencesJournalsSeparated = [];
      const booksChunked = [];

      while (res.data.length > 0) {
        if (res.data[0].book.type_id === 1) {
          booksSeparated.push(res.data.splice(0, 1)[0]);
        } else {
          conferencesJournalsSeparated.push(res.data.splice(0, 1)[0]);
        }
      }

      while (booksSeparated.length > 0) {
        booksChunked.push(booksSeparated.splice(0, 6));
      }
      this.setState(
        {
          textBooks: booksChunked,
          conferencesJournals: conferencesJournalsSeparated,
          loading: false
        }
      );
    }).catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { textBooks, conferencesJournals, loading } = this.state;

    return (
      <div className="publisher">
        <h1 className="publisher_title">Publications & Resources</h1>
        <TextbookSection books={textBooks} loading={loading} />
        <Journals conferencesJournals={conferencesJournals} loading={loading} />
        {/* <BlogSection /> */}
        <PublisherSection />
      </div>
    );
  }
}

export default Publisher;
