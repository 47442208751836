import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomCheckbox from '../../../elements/CustomCheckbox/CustomCheckbox';

/**
 * Renders Component
 * @param {object} props - Defined in proptypes
 * @returns {JSX.Element} - DOM description
 */
const BestPracticesModal = (props) => {
  const {
    open, closeModalManual, closeModalAuto, type, question, cancelToken
  } = props;
  const [hidePopupPermanently, setHidePopupPermanently] = useState(false);

  const handleClose = () => {
    if (type === 'auto') {
      closeModalAuto(question, cancelToken, hidePopupPermanently);
    } else {
      closeModalManual();
    }
  };

  const onChange = () => {
    setHidePopupPermanently(!hidePopupPermanently);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '25px'
        }
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '2rem',
          fontWeight: 'bold',
          color: 'secondary.main'
        }}
      >
        Sofie Best Search Practices
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'secondary.main'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBottom: 0,
        }}
      >
        <ul>
          <li>
            <strong>
              Use 2-4 search terms to provide context
            </strong>
            {' '}
            (e.g., “treatment diabetes insipidus dog”). Or pose questions like you would ask a
            colleague (e.g., “What is the treatment for...?”).
          </li>
          <li>
            <strong>
              Check your spelling.
            </strong>
            {' '}
            Right click on red underlined words for suggestions.
          </li>
        </ul>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginBottom: 2
        }}
      >
        {type === 'auto' ? (
          <Box>
            <CustomCheckbox
              checked={hidePopupPermanently}
              onChange={onChange}
              checkboxColor="primary"
              label="Don't show this message again"
              labelColor="primary"
            />
          </Box>
        ) : ''}
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BestPracticesModal;

BestPracticesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModalManual: PropTypes.func.isRequired,
  closeModalAuto: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'click',
    'auto',
    ''
  ]).isRequired,
  cancelToken: PropTypes.shape({}),
  question: PropTypes.string,
};

BestPracticesModal.defaultProps = {
  question: '',
  cancelToken: {}
};
