import React, { Component } from 'react';
import * as Proptypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import AuthRequest from '../../api/AuthRequest';
import 'bootstrap/dist/css/bootstrap.css';
import './TextPage.scss';

/**
 * Generates the pages that just display text like the terms and disclaimer pages.
 */
class TextPage extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      content: '',
      page: ''
    };
    this.getTextContent = this.getTextContent.bind(this);
  }

  /**
   * Triggers switch for retrieving page content.
   */
  componentDidMount() {
    const { match } = this.props;
    if (match.params[0] === 'agreement') {
      this.getTextContent('agreement');
    }
    if (match.params[0] === 'disclaimer') {
      this.getTextContent('disclaimer');
    }
    if (match.params[0] === 'privacy-policy') {
      this.getTextContent('privacy-policy');
    }
  }

  /**
   * Retrieves the basic text pages content from the database.
   * @param {string} page - page to retrieve text for
   */
  getTextContent(page) {
    const { match } = this.props;

    this.setState({
      page: match.params[0],
      loading: true
    });

    AuthRequest({
      method: 'get',
      url: page,
    }).then((res) => {
      this.setState({
        content: res.data.message,
        loading: false
      });
    }).catch(() => {
      this.setState({
        content: <p>there was an error!</p>,
        loading: false
      });
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { match } = this.props;
    const { content, loading, page } = this.state;
    let contentRender = (
      <div className="pt-4 d-flex justify-content-center textPage_loader">
        <Spinner animation="border" />
      </div>
    );

    if (match.params[0] !== page) {
      this.getTextContent(match.params[0]);
    }

    if (!loading) {
      contentRender = (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{
          __html: content
        }}
        />
      );
    }

    return (
      <div className="textPage">
        {contentRender}
      </div>
    );
  }
}

export default TextPage;

TextPage.propTypes = {
  match: Proptypes.shape({
    params: Proptypes.arrayOf(Proptypes.string)
  }).isRequired
};
