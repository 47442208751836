import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

/**
 * Renders Component
 * @param {object} props - Defined in proptypes
 * @returns {JSX.Element} - DOM description
 */
const CustomCheckbox = (props) => {
  const {
    checkboxColor, labelColor, label, checked, onChange
  } = props;
  let labelColorName;
  let checkboxColorName;

  switch (labelColor) {
    case 'primary':
      labelColorName = 'primary.main';
      break;
    case 'secondary':
      labelColorName = 'secondary.main';
      break;
    default:
      labelColorName = 'text.primary';
  }

  switch (checkboxColor) {
    case 'primary':
      checkboxColorName = 'primary.main';
      break;
    case 'secondary':
      checkboxColorName = 'secondary.main';
      break;
    default:
      checkboxColorName = 'text.primary';
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            color={checkboxColor}
            onChange={onChange}
            sx={{
              color: checkboxColorName
            }}
          />
        )}
        label={label}
        sx={{
          color: labelColorName
        }}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  checkboxColor: PropTypes.oneOf([
    'primary',
    'secondary'
  ]),
  labelColor: PropTypes.oneOf([
    'default',
    'primary',
    'secondary'
  ]),
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

CustomCheckbox.defaultProps = {
  checkboxColor: 'primary',
  labelColor: 'default',
  label: ''
};
