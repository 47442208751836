exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".loadingPage{display:flex;flex-flow:column nowrap;justify-content:center}.loadingPage_title{align-self:center;font-size:3rem;font-weight:500;margin-top:4rem}.loadingPage_loader{align-self:center;margin-top:4rem;color:#f8992d}.loadingPage_spinner-border{width:4rem !important;height:4rem !important;border-width:0.35em !important}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};