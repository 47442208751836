import React from 'react';
import * as Proptypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './Journals.scss';

/**
 * Generate the conference journals section of the publisher page.
 * @param {object} props - defined in proptypes
 * @returns {*} - Conference Journals Section
 * @class
 */
const Journals = (props) => {
  const { conferencesJournals, loading } = props;

  let conferencesJournalsSection = '';

  if (loading) {
    conferencesJournalsSection = (
      <div className="journals">
        <div className="journals_loader_title">
          Fetching Journals
        </div>
        <div className="journals_loader">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }

  if (conferencesJournals.length <= 0) {
    conferencesJournalsSection = (
      <div>
        No journals or conference proceedings found.
      </div>
    );
  } else {
    conferencesJournalsSection = conferencesJournals.map((conferenceJournal) => (
      <div className="journals_journals_journal" key={conferenceJournal.id}>
        <div className="journals_journals_journal_holder">
          <img
            src={`${UM_URL}media/book-banner/${conferenceJournal.image}`}
            alt={`${conferenceJournal.book.name} Logo`}
            className="journals_journals_journal_image"
          />
        </div>
        <p className="journals_journals_journal_title">
          {`${conferenceJournal.book.name} (${conferenceJournal.year})`}
        </p>
      </div>
    ));
  }

  return (
    <div className="journals">
      <div className="journals_title">
        Conference Proceedings and Journals
      </div>
      <div className="d-flex justify-content-center">
        <div className="journals_journals">
          {conferencesJournalsSection}
        </div>
      </div>
    </div>
  );
};

export default Journals;

Journals.propTypes = {
  loading: Proptypes.bool.isRequired,
  conferencesJournals: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.number,
      title: Proptypes.string,
      logo: Proptypes.string,
      year: Proptypes.number,
    })
  )
};

Journals.defaultProps = {
  conferencesJournals: '',
};
