exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".ask-answer{display:flex;flex-flow:row nowrap;border-bottom:1px solid #ececec;padding:0 1rem}.ask-answer_leftCol{max-width:2rem;padding:1rem 0}.ask-answer_interactive{display:flex;flex-flow:row;margin-left:1rem;cursor:pointer;padding:1rem 0;width:100%}.ask-answer_interactive_leftCol{width:100%}.ask-answer_interactive_rightCol{margin-left:1rem;align-self:center;max-width:2rem}.ask-answer_interactive:hover .ask-answer_arrow{opacity:1}.ask-answer_title{color:#003854;font-size:large;font-weight:500}.ask-answer_author{font-size:16px}.ask-answer_author a{color:#2E2EFF}.ask-answer_text{overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.ask-answer_text .truncate-overflow::before{position:absolute;content:\"...\";inset-block-end:0;inset-inline-end:0}.ask-answer_text .truncate-overflow::after{content:\"\";position:absolute;inset-inline-end:0;width:1rem;height:1rem;background:white}.ask-answer_arrow{font-size:40px;color:#f8992d;opacity:1}.ask-answer_check{font-size:0.8rem}.ask-answer:hover{background-color:#ececec}.ask-answer_favourite_button{color:#f8992d}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};