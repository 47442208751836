import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {
  FAVOURITE_ADD_REQUEST, FAVOURITE_ADD_SUCCESS, FAVOURITE_ADD_FAILURE,
  FAVOURITE_REMOVE_REQUEST, FAVOURITE_REMOVE_SUCCESS, FAVOURITE_REMOVE_FAILURE,
  FAVOURITE_LIST_REQUEST, FAVOURITE_LIST_SUCCESS, FAVOURITE_LIST_FAILURE,
  NOTES_ADD_REQUEST, NOTES_ADD_SUCCESS, NOTES_ADD_FAILURE
} from '../actions';
import AuthRequest from '../../api/AuthRequest';

// Add Favourite List
/**
 * Action creator for sending add to favourite request
 * @param {object} id - article id
 * @returns {object} - action
 */
function favouriteAddRequest() {
  return {
    type: FAVOURITE_ADD_REQUEST,
  };
}

/**
 * Action creator for user accepting add to favourite
 * @returns {object} - action
 */
function favouriteAddSuccess(docId, favouriteId) {
  return {
    type: FAVOURITE_ADD_SUCCESS,
    payload: {
      doc_id: docId,
      favourite_id: favouriteId
    }
  };
}

/**
 * Action creator for failing to add to favourite
 * @param {object} id - article id
 * @returns {object} - action
 */
function favouriteAddFailure(id) {
  return {
    type: FAVOURITE_ADD_FAILURE,
    payload: { id }
  };
}

// Remove Favourite List
/**
 * Action creator for sending remove favourite request
 * @param {object} id - article id
 * @returns {object} - action
 */
function favouriteRemoveRequest() {
  return {
    type: FAVOURITE_REMOVE_REQUEST,
  };
}

/**
 * Action creator for user accepting add remove favourite
 * @returns {object} - action
 */
function favouriteRemoveSuccess(id) {
  return {
    type: FAVOURITE_REMOVE_SUCCESS,
    payload: { id }
  };
}

/**
 * Action creator for user getting favourite list
 * @returns {object} - action
 */
function favouriteRemoveFailure() {
  return {
    type: FAVOURITE_REMOVE_FAILURE,
  };
}

// Get Favourite List
/**
 * Action creator for user getting favourite list
 * @returns {object} - action
 */
function favouriteListRequest() {
  return {
    type: FAVOURITE_LIST_REQUEST,
  };
}

/**
 * Action creator for user getting favourite list success
 * @param {object} favouriteList - favourite list
 * @returns {object} - action
 */
function favouriteListSuccess(favouriteList) {
  return {
    type: FAVOURITE_LIST_SUCCESS,
    payload: favouriteList,
  };
}

/**
 * Action creator for failing to get favourite list
 * @returns {object} - action
 */
function favouriteListFailure() {
  return {
    type: FAVOURITE_LIST_FAILURE,
  };
}

function notesAddRequest() {
  return {
    type: NOTES_ADD_REQUEST,
  };
}

function notesAddSuccess(favouriteId, notes) {
  return {
    type: NOTES_ADD_SUCCESS,
    payload: {
      favouriteId: favouriteId,
      notes: notes
    }
  }
}

function notesAddFailure(error) {
  return {
    type: NOTES_ADD_FAILURE,
    payload: error
  };
}

// ===== MIDDLEWARE =====

/**
 * @param id
 */
export function addtoFavourite(id) {
  return (dispatch) => {
    dispatch(favouriteAddRequest());
    AuthRequest({
      method: 'post',
      url: '/v3/favourites',
      data: {
        article_id: id,
      },
    })
      .then((res) => {
        ReactGA.event({
          category: 'Favourites',
          action: 'add-to-fav',
        });
        ReactGA4.event('add-to-fav');
        dispatch(favouriteAddSuccess(id, res.data.message.favourite_id));
      })
      .catch((error) => {
        dispatch(favouriteAddFailure(error.message));
      });
  };
}

export function getFavouriteList() {
  return (dispatch) => {
    dispatch(favouriteListRequest());
    AuthRequest({
      method: 'get',
      url: '/v3/favourites',
    }).then((res) => {
      dispatch(favouriteListSuccess(res.data));
    }).catch((error) => {
      dispatch(favouriteListFailure(error.message));
    });
  };
}

export function removeFavourite(id) {
  return (dispatch) => {
    dispatch(favouriteRemoveRequest());
    AuthRequest({
      method: 'DELETE',
      url: '/v3/favourites',
      data: {
        article_id: id,
      },
    })
      .then((res) => {
        dispatch(favouriteRemoveSuccess(id));
      })
      .catch((error) => {
        dispatch(favouriteRemoveFailure(error.message));
      });
  };
}

export function addNotes(favouriteId, notes) {
  return (dispatch) => {
    dispatch(notesAddRequest());
    AuthRequest({
      method: 'post',
      url: 'notes/add',
      params: {
        favourite_id: favouriteId,
        body: notes
      }
    }).then((res) => {
      ReactGA.event({
        category: 'Favourites',
        action: 'add-notes'
      });
      ReactGA4.event('add-notes');
      dispatch(notesAddSuccess(favouriteId, res.data));
    }).catch((error) => {
      dispatch(notesAddFailure(error.message));
    });
  };
}
