import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import AuthRequest from '../../../api/AuthRequest';
import Button from '../../Button/Button';
import './PublisherSection.scss';


/**
 * Generates the section of the page that lists the publishers.
 */
class PublisherSection extends Component {
  /**
   * Initializes component
   * @param {object} props - defined in proptypes
   */
  constructor(props) {
    super(props);
    this.state = {
      publishers: '',
    };
  }

  /**
   * Retrieves the list of all the publishers.
   */
  componentDidMount() {
    AuthRequest({
      method: 'get',
      url: 'all-publishers',
    }).then((res) => {
      this.setState(
        {
          publishers: res.data,
        }
      );
    });
  }

  /**
   * Renders component
   * @returns {*} - DOM description
   */
  render() {
    const { publishers } = this.state;

    let publishPartnersSection = (
      <div>
        No publishers were found.
      </div>
    );

    if (!publishers) {
      publishPartnersSection = (
        <div className="publishersection">
          <div className="publishersection_loader_title">
            Fetching Publishers
          </div>
          <div className="publishersection_loader">
            <Spinner animation="border" />
          </div>
        </div>
      );
    }

    if (publishers.length > 0) {
      publishPartnersSection = publishers.map((publisher) => (
        <div className="publishersection_publishers_publisher" key={publisher.id}>
          <div className="publishersection_image_holder">
            <Button
              onClick={() => window.open(publisher.website, '_blank')}
              variant="custom"
              customVariant="publishersection_link"
              customContent={(
                <img
                  src={`${UM_URL}media/book-banner/${publisher.logo}`}
                  alt={`${publisher.name} Logo`}
                  className="publishersection_image"
                />
              )}
              squareButton
              height="100%"
              width="100%"
            />
          </div>
        </div>
      ));
    }

    return (
      <div className="publishersection">
        <div className="publishersection_title">
          Publishing Partners
        </div>
        <div className="d-flex justify-content-center">
          <div className="publishersection_publishers">
            {publishPartnersSection}
          </div>
        </div>
      </div>
    );
  }
}

export default PublisherSection;
