import React from 'react';
import * as Proptypes from 'prop-types';
import Modal from 'react-modal';
import './ShareArticleModal.scss';
import ShareArticleForm from '../../ShareArticleForm/ShareArticleForm';
// This helps make the modal accessible.
Modal.setAppElement(document.getElementById('sofie-embed'));

const ShareArticleModal = (props) => {
  const {
    isOpen, onClose, title, subtitle, docId, enlytonID
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="ShareArticleModal Modal"
      className="ShareArticleModal"
      overlayClassName="ShareArticleModal_overlay"
      bodyOpenClassName="ShareArticleModal_parent"
    >
      <>
        <div className="ShareArticleModal_header">
          <div className="ShareArticleModal_header_title">{title}</div>
          <div className="ShareArticleModal_header_subtitle">{subtitle}</div>
        </div>
        <div className="ShareArticleModal_body">
          <p className="ShareArticleModal_body_title">Share with...</p>
          <div className="ShareArticleModal_body_message">
            <ShareArticleForm
              title={title}
              subtitle={subtitle}
              docId={docId}
              enlytonID={enlytonID}
              onClose={onClose} />
          </div>
        </div>
      </>
    </Modal >
  );
};

export default ShareArticleModal;

ShareArticleModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  title: Proptypes.string.isRequired,
  subtitle: Proptypes.string.isRequired,
  onClose: Proptypes.func.isRequired,
  docId: Proptypes.string.isRequired,
  enlytonID: Proptypes.string.isRequired
};
